import React from 'react'
import {connect} from 'react-redux'
import {Form, Field, Formik} from 'formik'
import {compose, withHandlers} from 'recompose'
import {useTranslation} from 'react-i18next'
import Yup from 'lib/yup'
import {Box, Flex, Input as BaseInput, Button, Text} from 'components'
import {login} from 'user/signup'
import steps from '../steps'

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

const Input = ({field: {name, ...field}, form, ...rest}) => (
  <Flex flexDirection="column" mb={3}>
    <BaseInput last name={name} {...field} {...rest} />
    {form.touched[name] && form.errors[name] && (
      <Box mt={2}>
        <Text danger>{form.errors[name]}</Text>
      </Box>
    )}
  </Flex>
)

const LoginForm = (props) => {
  const [t] = useTranslation()

  return (
    <Formik {...props} validationSchema={validationSchema}>
      {() => (
        <Form>
          <Box mb={4}>
            <Field
              name="email"
              placeholder={`${t('Email')} *`}
              type="email"
              component={Input}
            />
            <Field
              type="password"
              name="password"
              placeholder={`${t('Password')} *`}
              component={Input}
            />
          </Box>

          <Button primary block>
            {t('Log ind')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
const enhancer = compose(
  connect(null, {login: login.requested}),
  withHandlers({
    onSubmit: ({login, setStep}) => (values, {setStatus, setFieldError}) =>
      new Promise((resolve, reject) =>
        login({
          ...values,
          resolve,
          reject,
        })
      )
        .then(() => setStep(steps.GROUPS))
        .catch((error) => setFieldError('email', error)),
  })
)

export default enhancer(LoginForm)
