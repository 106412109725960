import React, {useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {useTranslation} from 'react-i18next'
import {Modal, Box} from 'components'
import {update} from 'sponsors/actions'
import {getById} from 'sponsors/selectors'
import Form from './Form'

const EditModal = ({sponsor, hide, update}) => {
  const handleSubmit = useCallback(
    ({logo, ...values}) => {
      new Promise((resolve, reject) => {
        update({resolve, reject, ...values})
      }).then(hide)
    },
    [update, hide]
  )

  const [t] = useTranslation()

  return (
    <Modal title={t('Rediger')} hide={hide}>
      <Box p={3}>
        <Form isEdit initialValues={sponsor} onSubmit={handleSubmit} />
      </Box>
    </Modal>
  )
}

const enhancer = connect(
  createStructuredSelector({
    sponsor: getById,
  }),
  {
    update: update.requested,
  }
)

export default enhancer(EditModal)
