import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Formik, Field} from 'formik'
import {withTranslation} from 'react-i18next'
import {Flex} from '@rebass/grid'
import {Button, Modal, TextArea} from 'components'
import {updatePayer} from 'payments/actions'

class InternalNoteModal extends Component {
  handleSubmit = (values) =>
    new Promise((resolve, reject) => {
      this.props.updatePayer({values, resolve, reject})
    }).then(this.props.hide)

  render() {
    const {hide, t, ...props} = this.props

    return (
      <Modal hide={hide} title={`${t('Redigér note')}...`}>
        <Flex flexDirection="column" p={3}>
          <Formik onSubmit={this.handleSubmit} {...props}>
            {({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="internalNote"
                  placeholder={`${t('Indtast note')}...`}
                  rows="3"
                  component={TextArea}
                />

                <Button primary small block>
                  {t('Gem')}
                </Button>
              </form>
            )}
          </Formik>
        </Flex>
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  updatePayer: updatePayer.requested,
}

const enhancer = connect(null, mapDispatchToProps)

export default withTranslation()(enhancer(InternalNoteModal))
