import {noop} from 'lodash'
import {parse} from 'date-fns'
import {put, call, take, takeLatest, select} from 'redux-saga/effects'

import {apiRequest} from 'app/sagas'
import {getActive} from 'clubs/selectors'

import * as actions from './actions'

const toUTC = (date) =>
  `${date.getUTCFullYear()}-${twoDigits(date.getUTCMonth() + 1)}-${twoDigits(
    date.getUTCDate()
  )}T${twoDigits(date.getUTCHours())}:${twoDigits(date.getUTCMinutes())}`

const twoDigits = (n) => (n < 10 ? `0${n}` : n)

function* watchFetch() {
  yield takeLatest(actions.fetch.requested, fetch)
}

function* fetch() {
  const {id} = yield select(getActive)
  try {

    const payments = yield call(apiRequest, `/payments/clubs/${id}`, {
      version: 'v3',
      method: 'get',
    })

    const activityPayments = yield call(
      apiRequest,
      `/activities/payments/overview/${id}`,
      {
        version: 'v3',
        method: 'get',
      }
    )

    yield put(actions.fetch.succeeded({payments, activityPayments}))
  } catch (error) {
    yield put(actions.fetch.failed(error))
  }
}

function* create() {
  while (true) {
    const {
      payload: {members = [], groups = [], ...values},
      meta: {resolve = noop, reject = noop},
    } = yield take(actions.create.requested)

    const club = yield select(getActive)

    try {
      const response = yield call(apiRequest, `/payments/clubs/${club.id}`, {
        version: 'v3',
        method: 'post',
        body: {
          ...values,
          requestDate: toUTC(parse(values.requestDate)),
          paymentStartDate: toUTC(parse(values.paymentStartDate)),
          individualAmount: values.individualAmount
            ? values.individualAmount.toString().replace(',', '.')
            : undefined,
          minimumAmount: values.minimumAmount
            ? values.minimumAmount.toString().replace(',', '.')
            : undefined,
            exemptAdminPayment: values.exemptAdminPayment,
        },
      })

      yield put(
        actions.addPayers.requested({members, groups, paymentId: response.id})
      )

      yield put(actions.create.succeeded(response))
      yield call(resolve, response.id)
    } catch (error) {
      yield put(actions.create.failed(error))
      yield call(reject)
    }
  }
}

function* update() {
  while (true) {
    const {
      payload: {id, values},
      meta: {resolve, reject},
    } = yield take(actions.update.requested)

    try {
      const response = yield call(apiRequest, `/payments/clubpayments/${id}`, {
        method: 'patch',
        version: 'v3',
        body: {
          ...values,
          requestDate: values.requestDate
            ? parse(values.requestDate)
            : undefined,
          paymentStartDate: values.paymentStartDate
            ? parse(values.paymentStartDate)
            : undefined,
        },
      })

      yield put(actions.update.succeeded(response))
      yield call(resolve)
    } catch (error) {
      yield put(actions.update.failed(error))
      yield call(reject)
    }
  }
}

function* addPayers() {
  while (true) {
    const {
      payload: {paymentId, members, groups},
      meta: {resolve = noop, reject = noop},
    } = yield take(actions.addPayers.requested)

    if (members.length > 0) {
      try {
        const response = yield call(
          apiRequest,
          `/payments/clubpayments/${paymentId}/users`,
          {
            version: 'v3',
            method: 'put',
            body: {
              '': members.map((member) => ({userId: member})),
            },
          }
        )

        yield put(actions.addPayers.succeeded(response))

        if (groups.length === 0) {
          yield call(resolve)
        }
      } catch (error) {
        yield put(actions.addPayers.failed(error))
        yield call(reject)
      }
    }

    if (groups.length > 0) {
      try {
        const response = yield call(
          apiRequest,
          `/payments/clubpayments/${paymentId}/usersfromgroups`,
          {
            version: 'v3',
            method: 'put',
            body: {
              '': groups.map((group) => ({userGroupId: group})),
            },
          }
        )

        yield put(actions.addPayers.succeeded(response))
        yield call(resolve)
      } catch (error) {
        yield put(actions.addPayers.failed(error))
        yield call(reject)
      }
    }
  }
}

function* removePayers() {
  while (true) {
    const {
      payload: {id, members},
      meta: {resolve, reject},
    } = yield take(actions.removePayers.requested)

    try {
      const response = yield call(
        apiRequest,
        `/payments/clubpayments/${id}/users`,
        {
          version: 'v3',
          method: 'delete',
          body: {
            '': members.map((member) => ({userId: member})),
          },
        }
      )

      yield put(actions.removePayers.succeeded(response))
      yield call(resolve)
    } catch (error) {
      yield put(actions.removePayers.failed(error))
      yield call(reject)
    }
  }
}

function* watchRemove() {
  yield takeLatest(actions.remove.requested, remove)
}

function* remove({payload: id}) {
  try {
    yield call(apiRequest, `/payments/clubpayments/${id}`, {
      version: 'v3',
      method: 'delete',
    })

    yield put(actions.remove.succeeded(id))
  } catch (error) {
    yield put(actions.remove.failed(error))
  }
}

function* sendReminder() {
  while (true) {
    const {
      payload: {id, member},
    } = yield take(actions.sendReminder.requested)

    try {
      yield call(
        apiRequest,
        `/payments/clubpayments/${id}/users/${member}/sendreminder`,
        {
          method: 'post',
          version: 'v3',
        }
      )

      yield put(actions.sendReminder.succeeded({id, member}))
    } catch (error) {
      yield put(actions.sendReminder.failed(error))
    }
  }
}

function* approve() {
  while (true) {
    const {
      payload: {id, member, userPaymentId, status, internalNote},
    } = yield take(actions.approve.requested)

    try {
      const response = userPaymentId
        ? yield call(apiRequest, `/payments/${userPaymentId}`, {
            version: 'v3',
            method: 'put',
            body: {internalNote, status, userId: member},
          })
        : yield call(
            apiRequest,
            `/payments/clubpayments/${id}/users/${member}/approve`,
            {
              version: 'v3',
              method: 'post',
            }
          )

      yield put(actions.approve.succeeded(response))
    } catch (error) {
      yield put(actions.approve.failed(error))
    }
  }
}

function* reject() {
  while (true) {
    const {
      payload: {id, member, userPaymentId},
    } = yield take(actions.reject.requested)

    try {
      const response = userPaymentId
        ? yield call(apiRequest, `/payments/${userPaymentId}`, {
            version: 'v3',
            method: 'patch',
            body: {status: 5},
          })
        : yield call(
            apiRequest,
            `/payments/clubpayments/${id}/users/${member}/reject`,
            {
              version: 'v3',
              method: 'post',
            }
          )

      yield put(actions.reject.succeeded(response))
    } catch (error) {
      yield put(actions.reject.failed(error))
    }
  }
}

function* download() {
  while (true) {
    const {
      payload: {id, activity},
      meta: {resolve, reject},
    } = yield take(actions.download.requested)

    const {id: clubId} = yield select(getActive)

    try {
      const response = yield call(
        apiRequest,
        activity
          ? `/activities/${id}/payments/${clubId}/export`
          : `/payments/clubpayments/${id}/export`,
        {
          returnRaw: true,
          version: 'v3',
        }
      )

      const text = yield call([response, 'text'])
      yield call(resolve, text)
    } catch (error) {
      yield call(reject, error)
    }
  }
}

function* updatePayer() {
  while (true) {
    const {
      payload: {paymentId, memberId, internalNote},
      meta: {resolve, reject},
    } = yield take(actions.updatePayer.requested)

    try {
      const response = yield call(
        apiRequest,
        `/payments/clubpayments/${paymentId}/users/${memberId}/note`,
        {
          method: 'post',
          version: 'v3',
          body: {
            '': internalNote,
          },
        }
      )

      yield put(actions.updatePayer.succeeded(response))
      yield call(resolve)
    } catch (error) {
      yield call(reject)
    }
  }
}

function* watchFetchPayers() {
  yield takeLatest(actions.fetchPayers.requested, fetchPayers)
}

function* fetchPayers({payload: {id}, meta: {resolve, reject}}) {
  try {
    const {invitedUsers, invitedGroups} = yield call(
      apiRequest,
      `/payments/clubpayments/${id}/users`,
      {
        version: 'v3',
      }
    )

    yield put(actions.fetchPayers.succeeded())
    yield call(resolve, {invitedGroups, invitedUsers})
  } catch (error) {
    yield put(actions.fetchPayers.failed())
    yield call(reject)
  }
}

function* watchFetchActivityPayers() {
  yield takeLatest(actions.fetchActivityPayers.requested, fetchActivityPayers)
}

function* fetchActivityPayers({payload: id, meta: {resolve, reject}}) {
  const {id: clubId} = yield select(getActive)

  try {
    const payers = yield call(
      apiRequest,
      `/activities/${id}/payments/overview/${clubId}`,
      {
        version: 'v3',
      }
    )

    yield put(actions.fetchActivityPayers.succeeded({id, payers}))
    yield call(resolve)
  } catch (error) {
    yield call(reject, error)
  }
}

function* watchPaymentStream() {
  yield takeLatest(actions.paymentStream.requested, paymentStream)
}

function* paymentStream({
  payload: {format, ...values},
  meta: {resolve, reject},
}) {
  const {id} = yield select(getActive)

  try {
    const response = yield call(
      apiRequest,
      `/payments/clubpayments/${id}/${
        format === 'csv' ? 'export' : 'generatepdf'
      }`,
      {
        method: 'post',
        returnRaw: true,
        version: 'v3',
        body: values,
      }
    )

    if (!response.ok) {
      throw Error()
    }

    const data = yield call([
      response,
      format === 'pdf' ? 'arrayBuffer' : 'text',
    ])

    yield call(resolve, data)
    yield put(actions.paymentStream.succeeded())
  } catch (error) {
    yield call(reject)
    yield put(actions.paymentStream.failed())
  }
}

export default [
  watchFetch,
  create,
  update,
  addPayers,
  watchRemove,
  sendReminder,
  removePayers,
  approve,
  reject,
  download,
  updatePayer,
  watchFetchPayers,
  watchFetchActivityPayers,
  watchPaymentStream,
]
