import React, {useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Formik, Field, Form} from 'formik'
import {useTranslation} from 'react-i18next'
import Yup from 'lib/yup'
import {authenticate} from 'authentication'
import {Box, Flex, FormikInput as Input, Button, Link, Text} from 'components'
import {Login} from 'components/icons'
import {getCompanyName} from 'app/selectors'

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
})

const initialValues = {
  email: '',
  password: '',
}

const LoginForm = ({authenticate, companyName}) => {
  const handleSubmit = useCallback(
    ({email, password}, {setErrors}) => {
      new Promise((resolve, reject) => {
        authenticate({email, password, resolve, reject})
      }).catch((error) => setErrors({global: error}))
    },
    [authenticate]
  )

  const [t] = useTranslation()

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({errors}) => (
        <Form>
          <Field
            small
            white
            autoFocus
            border="1px solid"
            borderColor="inactive"
            name="email"
            type="email"
            placeholder="Email"
            component={Input}
          />
          <Field
            white
            small
            border="1px solid"
            borderColor="inactive"
            name="password"
            type="password"
            placeholder={t('Kode')}
            component={Input}
          />

          <Box mb={3}>
            <Button block purple bold type="submit">
              <Flex alignItems="center">
                <Box flex="1" mr={2}>
                  {t('Log ind')}
                </Box>
                <Login size={20} fill="white" />
              </Flex>
            </Button>
          </Box>

          {errors.global && (
            <Box mt={3}>
              <Text danger>{errors.global}</Text>
            </Box>
          )}
          <Box mt={3}>
            <Text right primary>
              <Link primary to="/forgot">
                {t('Glemt kodeord?')}
              </Link>
            </Text>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const enhancer = connect(
  createStructuredSelector({companyName: getCompanyName}),
  {
    authenticate: authenticate.requested,
  }
)

export default enhancer(LoginForm)
