import React, {Component} from 'react'
import styled from 'styled-components'
import {toLower, includes} from 'lodash'
import {withTranslation} from 'react-i18next'
import {Searchable, Input, Box} from 'components'
import Item from './Item'

const List = styled(Box).attrs({
  mb: 3,
})`
  max-height: 400px;
  overflow-y: scroll;
`

class MemberList extends Component {
  static predicate = (item, value) => {
    const lowerCaseValue = toLower(value)

    return (
      includes(toLower(item.firstName), lowerCaseValue) ||
      includes(toLower(item.surname), lowerCaseValue)
    )
  }

  state = {
    bulk: [],
  }

  toggleBulk = (id) => {
    this.setState(({bulk: prevBulk}) => ({
      bulk: includes(prevBulk, id)
        ? prevBulk.filter((i) => i !== id)
        : [...prevBulk, id],
    }))
  }

  render() {
    const {members, children, t} = this.props
    const {bulk} = this.state

    return (
      <Searchable items={members} predicate={MemberList.predicate}>
        {({items, handleChange}) => (
          <React.Fragment>
            <Input
              small
              onChange={handleChange}
              placeholder={`${t('Søg i medlemmer')}...`}
            />

            <List>
              {items.map(({id, ...item}) => (
                <Item
                  key={id}
                  toggleBulk={() => this.toggleBulk(id)}
                  checked={includes(bulk, id)}
                  {...item}
                />
              ))}
            </List>

            {children && children({bulk})}
          </React.Fragment>
        )}
      </Searchable>
    )
  }
}

export default withTranslation()(MemberList)
