import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {NotificationBadge} from 'components'
import {getInactiveMembersArray} from 'members/selectors'

const InactiveMembersBadge = ({inactive}) =>
  inactive > 0 ? <NotificationBadge value={inactive} purple /> : null

const enhancer = connect(
  createStructuredSelector({
    inactive: getInactiveMembersArray,
  }),
  null,
  ({inactive}) => ({
    inactive: inactive.length || 0,
  })
)

export default enhancer(InactiveMembersBadge)
