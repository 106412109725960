import React from 'react'
import {connect} from 'react-redux'
import qs from 'qs'
import {get} from 'lodash'
import {compose} from 'recompose'
import {withRouterParams} from 'lib/hoc'
import {getMember, getMembers} from 'members/selectors'
import {Box, Modal, Text, H2} from 'components'
import Relation from './Relation'
import {useTranslation} from 'react-i18next'

const RelationsModal = ({
  member,
  members,
  hide,
  params: {group, ...params},
}) => {
  const [t] = useTranslation()
  const renderRelation = ({userId: id, firstName, surname}) => (
    <Relation
      key={id}
      hide={hide}
      linkText={t('Gå til relation')}
      title={
        <Text light bold small>
          {firstName} {surname}
        </Text>
      }
      to={{
        pathname: '/members',
        search: qs.stringify({...params, openMember: id}),
      }}
    />
  )

  return (
    <Modal hide={hide} title={t('Relationer')}>
      <Box p={3}>
        {member &&
        get(member, 'parents.length', 0) === 0 &&
        get(member, 'children.length', 0) === 0 ? (
          <Text center secondary>
            {t('Der er ingen relationer')}
          </Text>
        ) : (
          <>
            {member.parents.length > 0 && (
              <>
                <H2>{t('Forældre')}</H2>
                {member.parents
                  .filter(({userId: id}) => members[id])
                  .map(renderRelation)}
              </>
            )}
            {member.children.length > 0 && (
              <>
                <H2>{t('Børn')}</H2>
                {member.children
                  .filter(({userId: id}) => members[id])
                  .map(renderRelation)}
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  )
}

RelationsModal.defaultProps = {
  members: {},
}

const enhancer = compose(
  withRouterParams,
  connect((state, {memberId}) => ({
    member: getMember(state, memberId),
    members: getMembers(state),
  }))
)

export default enhancer(RelationsModal)
