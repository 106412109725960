import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import styled from 'styled-components'
import {compose} from 'recompose'
import {Trans, useTranslation} from 'react-i18next'
import {withRouterParams} from 'lib/hoc'
import {Foldable, Text, ButtonWithProtectedAction, Box, Flex} from 'components'
import {Email, Alert} from 'components/icons'
import {sendInvitationEmails} from 'clubs/actions'
import {getCompanyName} from 'app/selectors'

const Container = styled(Box).attrs({
  p: 3,
})`
  background: ${(props) => props.theme.colors.secondaryLight};
`

const MissingInvitations = ({
  missing,
  invited,
  sendInvitationEmails,
  companyName,
}) => {
  const [t] = useTranslation()

  return (
    <Foldable
      purple
      initialOpen
      title={t(
        'Antal medlemmer, der har status som inaktiv, fordi de ikke har fået eller handlet på invitationsmail.'
      )}
    >
      <Container>
        {missing > 0 && (
          <Flex justifyContent="center">
            <Box mr={2}>
              <Alert size={16} fill="danger" />
            </Box>
            <Text center secondary>
              <Trans i18nKey="missingInvitations" count={missing}>
                Din forening har importeret <strong>{{count: missing}}</strong>
                medlemmer, som ikke har fået tilsendt en invitationsmail.
              </Trans>
            </Text>
          </Flex>
        )}

        {missing > 0 && (
          <Flex justifyContent="center" mt={3}>
            <ButtonWithProtectedAction
              purple
              title="Send invitationsmail"
              accept={sendInvitationEmails}
              text={t('usersDisappear')}
            >
              <Flex justifyContent="center">
                <Box mr={3}>
                  <Email size={20} fill="white" />
                </Box>
                {t('Send invitationsmail til alle {{count}} medlemmer', {
                  count: missing,
                })}
              </Flex>
            </ButtonWithProtectedAction>
          </Flex>
        )}

        {invited > 0 && (
          <Flex mt={3} justifyContent="center">
            <Box mr={2}>
              <Alert size={16} fill="warning" />
            </Box>
            <Text center secondary>
              <Trans
                i18nKey="invitedMembersNotCreated"
                count={invited}
                values={{companyName}}
              >
                Din forening har <strong>{{count: invited}}</strong> medlemmer,
                som har fået tilsendt en invitationsmail, men som ikke har
                oprettet sig som bruger i MinForening.
              </Trans>
            </Text>
          </Flex>
        )}

        {invited + missing > 0 && (
          <Text center secondary mt={3}>
            {t('sendEmailUnderActions')}
          </Text>
        )}
      </Container>
    </Foldable>
  )
}
const enhancer = compose(
  withRouterParams,
  connect(createStructuredSelector({companyName: getCompanyName}), {
    sendInvitationEmails: sendInvitationEmails.requested,
  })
)

export default enhancer(MissingInvitations)
