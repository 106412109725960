import {createActions} from 'redux-actions'
import {noop, castArray} from 'lodash'
import {normalize} from 'normalizr'

import {payment, activityPayment} from './schema'

export const {
  fetch,
  create,
  update,
  addPayers,
  remove,
  sendReminder,
  removePayers,
  approve,
  reject,
  download,
  paymentStream,
  updatePayer,
  fetchPayers,
  fetchActivityPayers,
} = createActions(
  {
    FETCH: {
      REQUESTED: [
        () => undefined,
        ({resolve, reject} = {resolve: noop, reject: noop}) => ({
          resolve,
          reject,
        }),
      ],
      FAILED: undefined,
      SUCCEEDED: ({payments, activityPayments}) => 
        normalize(
          {payments, activityPayments},
          {payments: [payment], activityPayments: [activityPayment]}
        ),
    },
    
    CREATE: {
      REQUESTED: [
        ({values}) => values,
        ({resolve, reject}) => ({
          resolve,
          reject,
        }),
      ],
      FAILED: undefined,
      SUCCEEDED: (p) => normalize(p, payment),
    },

    UPDATE: {
      REQUESTED: [
        ({id, values}) => ({id, values}),
        ({resolve, reject}) => ({resolve, reject}),
      ],
      FAILED: undefined,
      SUCCEEDED: (p) => normalize(p, payment),
    },
    ADD_PAYERS: {
      REQUESTED: [
        ({paymentId, members, groups}) => ({paymentId, members, groups}),
        ({resolve, reject}) => ({resolve, reject}),
      ],
      FAILED: undefined,
      SUCCEEDED: (p) => normalize(p, payment),
    },
    REMOVE_PAYERS: {
      REQUESTED: [
        ({id, members}) => ({id, members: castArray(members)}),
        ({resolve = noop, reject = noop}) => ({resolve, reject}),
      ],
      FAILED: undefined,
      SUCCEEDED: (p) => normalize(p, payment),
    },
    REMOVE: {
      REQUESTED: undefined,
      SUCCEEDED: undefined,
      FAILED: undefined,
    },
    APPROVE: {
      REQUESTED: undefined,
      SUCCEEDED: (p) => normalize(p, payment),
      FAILED: undefined,
    },
    REJECT: {
      REQUESTED: undefined,
      SUCCEEDED: (p) => normalize(p, payment),
      FAILED: undefined,
    },
    SEND_REMINDER: {
      REQUESTED: [
        ({id, member}) => ({id, member}),
        ({resolve, reject}) => ({resolve, reject}),
      ],
      SUCCEEDED: undefined,
      FAILED: undefined,
    },
    DOWNLOAD: {
      REQUESTED: [
        ({id, activity}) => ({id, activity}),
        ({resolve, reject}) => ({resolve, reject}),
      ],
      SUCCEEDED: undefined,
      FAILED: undefined,
    },
    PAYMENT_STREAM: {
      REQUESTED: [
        ({resolve, reject, ...rest}) => rest,
        ({resolve, reject}) => ({resolve, reject}),
      ],
      SUCCEEDED: undefined,
      FAILED: undefined,
    },
    UPDATE_PAYER: {
      REQUESTED: [
        ({values}) => values,
        ({resolve, reject}) => ({resolve, reject}),
      ],
      SUCCEEDED: (payload) => normalize(payload, payment),
    },
    FETCH_PAYERS: {
      REQUESTED: [({id}) => ({id}), ({resolve, reject}) => ({resolve, reject})],
      SUCCEEDED: undefined,
      FAILED: undefined,
    },
    FETCH_ACTIVITY_PAYERS: {
      REQUESTED: [({id}) => id, ({resolve, reject}) => ({resolve, reject})],
      SUCCEEDED: ({id, payers}) => {
        const {approvable, pending, completed} = (payers || []).reduce(
          (result, payer) => {
            if (payer.paymentStatus === 1) {
              return result
            }

            return {
              ...result,
              completed: [...result.completed, payer],
            }
          },
          {approvable: [], pending: [], completed: []}
        )

        return {
          id,
          approvable,
          pending,
          completed,
        }
      },
      FAILED: undefined,
    },
  },
  {prefix: 'payments'}
)
