import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import styled from 'styled-components'
import {Link, Flex, Box} from 'components'
import getBadge from 'lib/getBadge'
import {getTldLocale,} from 'app/selectors'

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;

  @media (min-width: 400px) {
    max-height: 50px;
  }
`

const DownloadTheApp = ({tldLocale}) => (
  <Flex justifyContent="center">
    <Box height={45} mr={2}>
      <Link
        external
        to="https://play.google.com/store/apps/details?id=dk.minforening.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      >
        <Image
          src={getBadge('googlePlay', tldLocale)}
          alt="Hent app'en på Google Play"
          style={{height: '100%'}}
        />
      </Link>
    </Box>

    <Box height={45}>
      <Link
        external
        to="https://itunes.apple.com/dk/app/minforening/id1149003433?l=da&mt=8"
      >
        <Image
          src={getBadge('appStore', tldLocale)}
          alt="Hent app'en på App Store"
          style={{height: '100%'}}
        />
      </Link>
    </Box>
  </Flex>
)

const enhancer = connect(createStructuredSelector({tldLocale: getTldLocale}))

export default enhancer(DownloadTheApp)
