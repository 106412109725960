import React from 'react'
import {Flex, Box} from '@rebass/grid'
import {LogoWithTagline} from 'components/icons'
import {DownloadTheApp, Text} from 'components'
import {useTranslation, Trans} from 'react-i18next'

const NoClub = () => {
  const [t] = useTranslation()
  return (
    <Flex flexDirection="column" alignItems="center">
      <LogoWithTagline width="100%" height="50px" />

      <Box my={4}>
        <Text bg="danger" light center>
          <strong>{t('Ugyldigt eller inaktivit invitationslink')}</strong>
        </Text>
      </Box>

      <Box style={{maxWidth: 350}}>
        <Box mb={4}>
          <Text center>
            <Trans i18nKey="contactTheInvitationSender">
              Tag kontakt til personen du modtog linket af, og se om du kan få
              et <strong>gyldigt invitationslink</strong> til din forening.
            </Trans>
          </Text>
        </Box>
        <Box mb={3}>
          <Text center>
            <Trans i18nKey="theAppCanBeDownloaded">
              MinForening App kan downloades via <strong>Google Play</strong>{' '}
              eller <strong>App Store</strong>
            </Trans>
          </Text>
        </Box>

        <DownloadTheApp />
      </Box>
    </Flex>
  )
}
export default NoClub
