import React from 'react'
import styled from 'styled-components'
import {Flex, Box} from '@rebass/grid'
import {Card, Text, DownloadTheApp} from 'components'
import {LogoWithTagline} from 'components/icons'
import {Trans} from 'react-i18next'

const Container = styled(Flex).attrs({
  p: 3,
  alignItems: 'center',
  justifyContent: 'center',
})`
  height: 100vh;
`

const Directions = () => (
  <Container>
    <Card shadow p={2} white width={350}>
      <Box mb={3} px={3}>
        <LogoWithTagline width="100%" height="100" />
      </Box>

      <Box mb={3}>
        <Text center>
          <Trans i18nKey="useTheWebsite">
            Hov, du kan ikke tilgå vores administrationsværktøj fra
            mobiltelefonen. Du bedes venligst gå ind på{' '}
            <strong>web.minforening.dk</strong> fra din computer for at få den
            fulde oplevelse.
          </Trans>
        </Text>
      </Box>

      <Box mb={3}>
        <Text center>
          <Trans i18nKey="getTheAppFrom">
            Hvis du vil se MinForening som medlem, skal du downloade MinForening
            på enten <strong>App Store</strong> eller{' '}
            <strong>Google Play</strong>
          </Trans>
        </Text>
      </Box>
      <DownloadTheApp />
    </Card>
  </Container>
)

export default Directions
