import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {createStructuredSelector} from 'reselect'
import {Redirect} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {withAuthenticationRequirement} from 'lib/hoc'
import {getHasClubs} from 'clubs/selectors'
import {getFirstName, getImagePath} from 'user/selectors'
import {Box, Flex, Page, Card, Image, Link, LinkButton, Text} from 'components'
import getLogo from 'lib/getLogo'
import {Association} from 'components/icons'
import {getTldLocale, getCompanyName} from 'app/selectors'
import member from 'images/member-default.png'

const Onboarding = ({
  firstName,
  imagePath,
  hasClubs,
  locale,
  companyName,
  match: {path, isExact},
}) => {
  const [t] = useTranslation()

  if (hasClubs) {
    return <Redirect to="/overview" />
  }

  return (
    <Page justifyContent="center" flex="1">
      <Helmet>
        <title>{t('Onboarding | {{companyName}}', {companyName})}</title>
      </Helmet>

      <Box>
        <Image
          src={getLogo(locale, 'dark')}
          width={300}
          mx="auto"
          mb={4}
          display="block"
        />
      </Box>

      <Card secondaryLight p={4} width={400} mx="auto">
        <Box width={250} mx="auto">
          <Flex justifyContent="center" mb={3}>
            <Image round src={imagePath || member} width="75" height="75" />
          </Flex>

          <Text center secondary mb={3}>
            {t('Velkommen {{name}}', {name: firstName})}
          </Text>

          <LinkButton to="/create-club" bold primary block>
            <Flex alignItems="center">
              <Association fill="white" size={16} />
              <Box flex="1">{t('Opret forening')}</Box>
            </Flex>
          </LinkButton>

          <Box mt={3}>
            <Text center>
              <Link secondary to="/logout">
                {t('Log ud')}
              </Link>
            </Text>
          </Box>
        </Box>
      </Card>
    </Page>
  )
}

const enhancer = compose(
  withAuthenticationRequirement,
  connect(
    createStructuredSelector({
      hasClubs: getHasClubs,
      firstName: getFirstName,
      imagePath: getImagePath,
      locale: getTldLocale,
      companyName: getCompanyName,
    })
  )
)

export default enhancer(Onboarding)
