import React, {useMemo, useEffect, useState, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {useTranslation} from 'react-i18next'
import {
  Box,
  Text,
  H2,
  Button,
  Image,
  ButtonWithProtectedAction,
} from 'components'
import {useToggle} from 'lib/hooks'
import {getCompanyName} from 'app/selectors'
import {fetch as fetchSponsors, remove} from 'sponsors/actions'
import {getRemaningViews, getAllAsArray as getAll} from 'sponsors/selectors'
import {getActive} from 'clubs/selectors'
import CreateModal from './CreateModal'
import EditModal from './EditModal'
import BuyViewsForm from './BuyViewsForm'

const Sponsor = ({
  companyName,
  sponsors,
  fetchSponsors,
  club,
  remaningViews,
  remove,
}) => {
  const isReady = useMemo(() => !!club, [club])

  useEffect(() => {
    if (isReady) {
      fetchSponsors()
    }
  }, [isReady, fetchSponsors])

  const [t] = useTranslation()
  const [createModalVisible, showCreateModal, hideCreateModal] = useToggle()

  const [editId, setEditId] = useState(null)
  const [editModalVisible, _showEditModal, _hideEditModal] = useToggle()
  const showEditModal = useCallback(
    (id) => {
      setEditId(id)
      _showEditModal()
    },
    [setEditId, _showEditModal]
  )
  const hideEditModal = useCallback(() => {
    setEditId(null)
    _hideEditModal()
  }, [setEditId, _hideEditModal])

  const handleRemove = useCallback(
    (id) => {
      remove(id)
    },
    [remove]
  )

  return (
    <>
      <Text>{t('settingsSponsorFirstText', {companyName})}</Text>
      <Text my={3}>{t('settingsSponsorSecondText', {companyName})}</Text>

      <Box mt={4}>
        <H2 mb={2}>{t('Køb sponsorvisninger')}</H2>
        <BuyViewsForm />
        <Text mt={3}>
          {t('Antal sponsorvisninger tilbage')}:{' '}
          <strong>{remaningViews}</strong>
        </Text>
      </Box>

      <Box mt={4}>
        <H2 mb={2}>{t('Sponsoroverblik')}</H2>

        {sponsors.length > 0 && (
          <table style={{borderCollapse: 'collapse'}}>
            <thead>
              <th>
                <Text textAlign="left" pr={5}>
                  {t('Billede')}
                </Text>
              </th>
              <th>
                <Text textAlign="left" pr={5}>
                  {t('Navn')}
                </Text>
              </th>
              <th>
                <Text textAlign="left" pr={5}>
                  {t('Link')}
                </Text>
              </th>
              <th>
                <Text textAlign="left">{t('Visninger')}</Text>
              </th>
              <th />
            </thead>
            <tbody>
              {sponsors.map(({id, title, url, logo, shownCount}) => (
                <tr key={id}>
                  <td>
                    <Image width={35} mr={2} src={logo} alt={title} />
                  </td>
                  <td>
                    <Text mr={2}>{title}</Text>
                  </td>
                  <td>
                    <Text mr={2}>{url}</Text>
                  </td>
                  <td>
                    <Text mr={2}>{shownCount}</Text>
                  </td>
                  <td>
                    <Button tiny mr={2} onClick={() => showEditModal(id)}>
                      {t('Rediger')}
                    </Button>
                    <ButtonWithProtectedAction
                      tiny
                      danger
                      accept={() => handleRemove(id)}
                    >
                      {t('Slet')}
                    </ButtonWithProtectedAction>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Button small mt={2} onClick={showCreateModal}>
          {t('Tilføj ny sponsor')}
        </Button>

        {createModalVisible && <CreateModal hide={hideCreateModal} />}
        {editModalVisible && <EditModal id={editId} hide={hideEditModal} />}
      </Box>
    </>
  )
}

const enhancer = connect(
  createStructuredSelector({
    companyName: getCompanyName,
    club: getActive,
    remaningViews: getRemaningViews,
    sponsors: getAll,
  }),
  {fetchSponsors: fetchSponsors.requested, remove: remove.requested}
)

export default enhancer(Sponsor)
