import React, {useRef, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Form, Field, Formik} from 'formik'
import {compose, withHandlers, branch, renderComponent} from 'recompose'
import {useTranslation} from 'react-i18next'
import i18n from 'i18n'
import Yup from 'lib/yup'
import {FormikInput as Input, Box, Button, TextArea} from 'components'
import {create} from 'user/signup'
import {getUser} from 'user/signup/selectors'
import MultiRegistration from './MultiRegistration'
import steps from '../steps'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email().required(),
  zip: Yup.string()
    .required()
    .matches(/^\d*$/, i18n.t('validation:mustBeANumber'))
    .test(
      'length',
      i18n.t('validation:minCiphers', {number: 4}),
      (val) => !val || val.length === 4
    ),
  birthdate: Yup.string().required(),
  mobile: Yup.string().required(),
  address: Yup.string().required(),
  city: Yup.string().required(),
  password: Yup.string().required(),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null]),
})

const RegistrationForm = ({initialValues, ...props}) => {
  const [t] = useTranslation()
  const birthdate = useRef()

  const handleBirthdateFocus = useCallback(() => {
    birthdate.current.type = 'date'
  }, [])

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        firstName: '',
        surname: '',
        email: '',
        zip: '',
        birthdate: '',
        mobile: '',
        address: '',
        city: '',
        note: '',
        password: '',
        passwordConfirmation: '',
        ...initialValues,
      }}
      {...props}
    >
      {({isSubmitting, isValid}) => (
        <Form>
          <Field
            name="firstName"
            placeholder={`${t('Fornavn')} *`}
            component={Input}
          />
          <Field
            name="surname"
            placeholder={`${t('Efternavn')} *`}
            component={Input}
          />
          <Field
            name="email"
            placeholder={`${t('Email')} *`}
            type="email"
            component={Input}
          />
          <Box mb={5}>
            <Field
              name="zip"
              placeholder={`${t('Postnummer')} *`}
              component={Input}
            />
          </Box>
          <Field
            name="birthdate"
            placeholder={`${t('Fødselsdato')} *`}
            type="text"
            innerRef={birthdate}
            onFocus={handleBirthdateFocus}
            component={Input}
          />
          <Field
            name="mobile"
            placeholder={`${t('Telefonnummer')} *`}
            type="phone"
            component={Input}
          />
          <Field
            name="address"
            placeholder={`${t('Adresse')} *`}
            component={Input}
          />
          <Field name="city" placeholder={`${t('By')} *`} component={Input} />
          <Field
            last
            rows={5}
            name="note"
            placeholder={t('Yderligere information til din forening')}
            component={TextArea}
          />
          <Box mt={5}>
            <Field
              type="password"
              name="password"
              placeholder={`${t('Kode')} *`}
              component={Input}
            />
            <Field
              type="password"
              name="passwordConfirmation"
              placeholder={`${t('Kode gentaget')} *`}
              component={Input}
            />
          </Box>

          <Button
            primary
            block
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            {t('Opret bruger')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const enhancer = compose(
  connect(createStructuredSelector({user: getUser}), {
    create: create.requested,
  }),
  branch(
    ({user}) => user && user.length > 0,
    renderComponent(MultiRegistration)
  ),
  withHandlers({
    onSubmit: ({create, setStep}) => (values, {setFieldError}) =>
      new Promise((resolve, reject) => create({...values, resolve, reject}))
        .then(() => setStep(steps.GROUPS))
        .catch((error) => setFieldError('email', error)),
  })
)

export default enhancer(RegistrationForm)
