import React, {useMemo} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import includes from 'lodash/includes'
import {useTranslation} from 'react-i18next'
import Item from './Item'
import {
  Calendar,
  Association,
  People,
  Payment,
  Settings,
  Email,
} from 'components/icons'
import PaymentsNotificationBadge from './PaymentsNotificationBadge'
import OutstandingPaymentsNotificationBadge from './OutstandingPaymentsNotificationBadge'
import PendingMembersNotificationBadge from './PendingMembersNotificationBadge'
import InactiveMembersBadge from './InactiveMembersBadge'
import GroupRequestsNotificationBadge from './GroupRequestsNotificationBadge'
import {getIsGroupLeader} from 'user'

const Menu = ({isGroupLeader, location: {pathname}}) => {
  const [t] = useTranslation()

  const items = useMemo(
    () => [
      {
        name: t('Overblik'),
        icon: Association,
        path: '/overview',
      },
      {
        name: t('Aktiviteter'),
        icon: Calendar,
        path: '/activities',
      },
      {
        name: t('Medlemmer'),
        icon: People,
        path: '/members',
        badges: [
          PendingMembersNotificationBadge,
          InactiveMembersBadge,
          GroupRequestsNotificationBadge,
        ],
      },
      {
        name: t('Besked_plural'),
        path: '/messages',
        icon: Email,
        disabled: isGroupLeader,
      },
      {
        name: t('Betalinger'),
        icon: Payment,
        path: '/payments',
        disabled: isGroupLeader,
        badges: [
          PaymentsNotificationBadge,
          OutstandingPaymentsNotificationBadge,
        ],
      },
      {
        name: t('Indstillinger'),
        icon: Settings,
        disabled: isGroupLeader,
        path: '/settings',
      },
    ],
    [isGroupLeader, t]
  )

  return items.map((item) => (
    <Item
      key={item.name}
      active={includes(
        item.path,
        pathname.split('/').filter((part) => !!part)[0]
      )}
      {...item}
    />
  ))
}

const enhancer = compose(
  withRouter,
  connect(
    createStructuredSelector({
      isGroupLeader: getIsGroupLeader,
    })
  )
)

export default enhancer(Menu)
