import React, {useCallback} from 'react'
import {connect} from 'react-redux'
import {Formik, Form, Field} from 'formik'
import {subDays, startOfToday, format} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {
  Flex,
  Box,
  Text,
  Input,
  FormikAutoSubmit,
  Loading,
  Button,
} from 'components'
import {fetchStatistics} from 'members/actions'
import {getStatistics} from 'members/selectors'
import {typesById} from 'activities/constants'

const initialValues = {
  start: format(subDays(startOfToday(), 30), 'YYYY-MM-DD'),
  end: format(startOfToday(), 'YYYY-MM-DD'),
}

const Statistics = ({fetchStatistics, id, statistics}) => {
  const handleSubmit = useCallback(
    (values, {setSubmitting}) => {
      new Promise((resolve, reject) => {
        fetchStatistics({resolve, reject, id, ...values})
      }).then(() => setSubmitting(false))
    },
    [fetchStatistics, id]
  )

  const [t] = useTranslation()

  return (
    <Flex height={100} flexDirection="column" justifyContent="space-between">
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({isSubmitting}) => (
          <Form>
            <Flex mb={2} alignItems="flex-end">
              <Box width={2 / 5} mr={2}>
                <Text>{t('Fra')}</Text>
                <Field name="start">
                  {({field}) => <Input small last type="date" {...field} />}
                </Field>
              </Box>
              <Box width={2 / 5} mr={2}>
                <Text>{t('Til')}</Text>
                <Field name="end">
                  {({field}) => <Input small last type="date" {...field} />}
                </Field>
              </Box>
              <Box width={1 / 5}>
                <Button
                  primary
                  small
                  block
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Loading size={15} /> : t('Vis')}
                </Button>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>

      {statistics.length > 0 && (
        <Flex>
          {statistics
            .filter(({type}) => type > 0)
            .sort(
              ({type: typeA}, {type: typeB}) =>
                typesById[typeA].order - typesById[typeB].order
            )
            .map(
              (
                {
                  type,
                  activityCount,
                  participatingCount,
                  participationPercentage,
                },
                index,
                array
              ) => {
                const {color} = typesById[type]

                return (
                  <Box
                    flex={1}
                    borderRadius={3}
                    bg={color}
                    key={id}
                    py={1}
                    px={2}
                    mr={index === array.length - 1 ? 0 : 2}
                  >
                    <Text small center color="white">
                      {participationPercentage}% ({participatingCount}/
                      {activityCount})
                    </Text>
                  </Box>
                )
              }
            )}
        </Flex>
      )}
    </Flex>
  )
}

const enhancer = connect(
  (state, {id}) => ({statistics: getStatistics(state, id)}),
  {fetchStatistics: fetchStatistics.requested}
)

export default enhancer(Statistics)
