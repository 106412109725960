import React from 'react'
import IconBase from './IconBase'

const Integrations = props => (
  <IconBase viewBox="0 0 41.79 41.34" {...props}>
    <g transform="translate(472.2 260.94)">
      <path d="m-469.45-245.85c0.22 0.75 0.52 1.48 0.91 2.16l-1.1 1.38c-0.36 0.45-0.32 1.1 0.08 1.51l1.89 1.89c0.41 0.41 1.06 0.44 1.51 0.08l1.38-1.09c0.71 0.41 1.46 0.73 2.24 0.95l0.21 1.77c0.07 0.57 0.55 1 1.13 1h2.68c0.57 0 1.06-0.43 1.13-1l0.2-1.71c0.84-0.22 1.65-0.55 2.41-0.97l1.33 1.05c0.45 0.36 1.1 0.32 1.51-0.09l1.89-1.89c0.41-0.41 0.44-1.06 0.08-1.51l-1.04-1.31c0.43-0.74 0.76-1.54 0.99-2.37l1.6-0.18c0.57-0.07 1-0.55 1-1.13v-2.68c0-0.57-0.43-1.06-1-1.13l-1.57-0.18c-0.22-0.82-0.54-1.6-0.95-2.34l0.97-1.22c0.36-0.45 0.32-1.1-0.08-1.51l-1.89-1.89c-0.41-0.41-1.06-0.44-1.51-0.08l-1.19 0.94c-0.77-0.45-1.6-0.8-2.46-1.03l-0.18-1.54c-0.07-0.57-0.55-1-1.13-1h-2.67c-0.57 0-1.06 0.43-1.13 1l-0.18 1.54c-0.89 0.24-1.74 0.6-2.52 1.07l-1.23-0.98c-0.45-0.36-1.1-0.32-1.51 0.08l-1.89 1.89c-0.41 0.41-0.44 1.06-0.09 1.51l1.03 1.3c-0.41 0.74-0.73 1.54-0.93 2.36l-1.65 0.19c-0.57 0.07-1 0.55-1 1.13v2.68c0 0.58 0.43 1.06 1 1.13l1.73 0.22zm9.69-7.23c2.44 0 4.42 1.98 4.42 4.42s-1.98 4.42-4.42 4.42-4.42-1.98-4.42-4.42 1.98-4.42 4.42-4.42z" />
      <path d="m-433.31-245.19l-1.41-1.19c-0.43-0.37-1.07-0.35-1.49 0.04l-0.78 0.73c-0.66-0.32-1.36-0.54-2.08-0.67l-0.22-1.08c-0.11-0.56-0.62-0.94-1.19-0.9l-1.84 0.16c-0.57 0.05-1.01 0.51-1.03 1.08l-0.04 1.09c-0.71 0.25-1.38 0.61-1.98 1.04l-0.93-0.62c-0.47-0.31-1.11-0.22-1.47 0.21l-1.19 1.42c-0.37 0.43-0.35 1.07 0.04 1.49l0.82 0.87c-0.28 0.64-0.48 1.3-0.6 1.99l-1.17 0.24c-0.56 0.11-0.94 0.62-0.9 1.19l0.16 1.84c0.05 0.57 0.51 1.01 1.08 1.03l1.26 0.04c0.23 0.6 0.53 1.17 0.89 1.7l-0.7 1.06c-0.31 0.47-0.22 1.11 0.21 1.47l1.41 1.19c0.43 0.37 1.07 0.35 1.49-0.04l0.92-0.86c0.61 0.28 1.24 0.49 1.9 0.62l0.26 1.26c0.11 0.56 0.62 0.94 1.19 0.9l1.84-0.16c0.57-0.05 1.01-0.51 1.03-1.08l0.04-1.24c0.67-0.24 1.31-0.56 1.9-0.96l1.02 0.67c0.47 0.31 1.11 0.22 1.48-0.21l1.19-1.41c0.37-0.43 0.35-1.07-0.04-1.49l-0.82-0.87c0.3-0.64 0.52-1.31 0.64-2l1.12-0.23c0.56-0.11 0.94-0.62 0.9-1.19l-0.16-1.84c-0.05-0.57-0.51-1.01-1.08-1.03l-1.13-0.04c-0.23-0.65-0.55-1.27-0.94-1.85l0.61-0.92c0.32-0.45 0.23-1.08-0.21-1.45zm-6.94 10.65c-1.99 0.17-3.75-1.31-3.92-3.3s1.31-3.75 3.3-3.92 3.75 1.31 3.92 3.3c0.17 2-1.31 3.75-3.3 3.92z" />
      <path d="m-463.01-229.58c-0.56 0.06-0.99 0.53-1 1.1l-0.01 1.14c-0.01 0.57 0.41 1.05 0.98 1.13l0.83 0.11c0.14 0.5 0.34 0.99 0.59 1.45l-0.54 0.66c-0.36 0.44-0.33 1.08 0.07 1.49l0.79 0.81c0.4 0.41 1.04 0.45 1.49 0.1l0.67-0.52c0.47 0.28 0.97 0.5 1.49 0.65l0.09 0.86c0.06 0.56 0.53 0.99 1.1 1l1.13 0.01c0.57 0.01 1.05-0.41 1.13-0.98l0.1-0.82c0.56-0.14 1.1-0.35 1.61-0.63l0.63 0.51c0.44 0.36 1.08 0.33 1.49-0.07l0.81-0.79c0.41-0.4 0.45-1.04 0.1-1.49l-0.48-0.62c0.3-0.49 0.52-1.02 0.68-1.57l0.74-0.08c0.56-0.06 0.99-0.53 1-1.1l0.01-1.13c0.01-0.57-0.41-1.05-0.98-1.13l-0.73-0.09c-0.14-0.55-0.34-1.07-0.61-1.57l0.45-0.56c0.36-0.44 0.33-1.08-0.07-1.49l-0.79-0.81c-0.4-0.41-1.04-0.45-1.49-0.1l-0.54 0.42c-0.51-0.31-1.06-0.55-1.63-0.71l-0.07-0.7c-0.06-0.56-0.53-0.99-1.1-1l-1.13-0.01c-0.57-0.01-1.05 0.41-1.13 0.98l-0.09 0.7c-0.59 0.15-1.16 0.38-1.69 0.69l-0.56-0.46c-0.44-0.36-1.08-0.33-1.49 0.07l-0.82 0.8c-0.41 0.4-0.45 1.04-0.1 1.49l0.48 0.62c-0.28 0.49-0.5 1.02-0.64 1.57l-0.77 0.07zm7.32-1.29c1.63 0.02 2.93 1.36 2.91 2.98-0.02 1.63-1.36 2.93-2.98 2.91-1.63-0.02-2.93-1.36-2.91-2.98s1.35-2.92 2.98-2.91z" />
    </g>
  </IconBase>
)

export default Integrations
