import React, {useEffect, useState, useCallback} from 'react'
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {every} from 'lodash'
import {useTranslation} from 'react-i18next'
import {Page, Flex, Button} from 'components'
import {fetch as fetchPayments} from 'payments/actions'
import {getGrouped} from 'payments'
import {useToggle} from 'lib/hooks'
import CreatePaymentModal from './CreatePaymentModal'
import ExportModal from './ExportModal'
import NoPayments from './NoPayments'
import PaymentsTable from './PaymentsTable' // Payment.js
import {getActive} from 'clubs'
import countryCodes from 'lib/countryCodes'
import {getCompanyName} from 'app/selectors'

const Overview = ({fetch, payments, club, companyName}) => {
  const [t] = useTranslation()
  const [exportFormat, setExportFormat] = useState()
  const [createModalVisible, showCreateModal, hideCreateModal] = useToggle()
  const [exportModalVisible, _showExportModal, _hideExportModal] = useToggle()

  const showExportModal = useCallback(
    (format) => {
      _showExportModal()
      setExportFormat(format)
    },
    [_showExportModal, setExportFormat]
  )

  const hideExportModal = useCallback(() => {
    _hideExportModal()
    setExportFormat(null)
  }, [_hideExportModal, setExportFormat])

  useEffect(() => {
    fetch()
  }, [fetch, club])

  return (
    <Page>
      <Helmet title={t('Betalinger | {{companyName}}', {companyName})} />
      <Flex justifyContent="flex-end" mb={4}>
        {club?.countryCode === countryCodes.da && (
          <Button mr={3} primary onClick={() => showExportModal('pdf')}>
            {t('MobilePay')}            
          </Button>
        )}
        <Button mr={3} primary onClick={() => showExportModal('csv')}>
          {t('Betalingsstrøm')}
        </Button>
        <Button primary onClick={showCreateModal}>
          {t('Opret betaling')}
        </Button>
      </Flex>

      {every(payments, (group) => group.length === 0) ? (
        <NoPayments showCreateModal={showCreateModal} />
      ) : (
        <PaymentsTable payments={payments} showCreateModal={showCreateModal} />
      )}

      {createModalVisible && <CreatePaymentModal hide={hideCreateModal} />}
      {exportModalVisible && (
        <ExportModal format={exportFormat} hide={hideExportModal} />
      )}
    </Page>
  )
}

const enhancer = connect(
  createStructuredSelector({
    companyName: getCompanyName,
    payments: getGrouped,
    club: getActive,
  }),
  {
    fetch: fetchPayments.requested,
  }
)

export default enhancer(Overview)
