import {createSelector} from 'reselect'
import {values} from 'lodash'

const getAll = (state) => state.sponsors.entities

const getId = (_, {id}) => id

export const getById = createSelector(
  [getAll, getId],
  (sponsors, id) => sponsors[id]
)

export const getAllAsArray = createSelector(getAll, values)

export const getViews = (state) => state.sponsors.views

export const getTotalViews = (state) => state.sponsors.totalViews

export const getRemaningViews = createSelector(
  [getViews, getTotalViews],
  (views, totalViews) => totalViews - views
)
