import React, {useState, useCallback, useMemo} from 'react'
import qs from 'qs'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {createStructuredSelector} from 'reselect'
import {Redirect} from 'react-router-dom'
import {pick, includes} from 'lodash'
import Yup from 'lib/yup'
import {Formik, Form, Field} from 'formik'
import {withRouterParams} from 'lib/hoc'
import {FormikInput as Input, Button, Flex, Box, H1, Link} from 'components'
import {getCurrent, getNumberOfUsers, save, getAllEmails} from 'signup/dummies'
import validationSchema from './validationSchema'
import {useTranslation} from 'react-i18next'

const New = ({
  user,
  save,
  numberOfUsers,
  emails,
  params: {type, ...params},
}) => {
  const [t] = useTranslation()
  const [saved, setSaved] = useState(false)

  const isChild = useMemo(() => type === 'child', [type])

  const validateEmail = useCallback(
    (value) => {
      if (includes(emails, value) && !isChild) {
        return t('Denne e-mail er allerede i brug')
      }
    },
    [emails, isChild, t]
  )

  const handleSubmit = useCallback(
    (values) => {
      save(values)
      setSaved(true)
    },
    [save, setSaved]
  )

  const initialValues = useMemo(
    () => ({
      ...pick(user, ['userId', 'firstName', 'surname', 'zip', 'oldEmail']),
      email:
        type === 'adult'
          ? Yup.string().email().isValidSync(user.oldEmail)
            ? user.oldEmail
            : ''
          : user.oldEmail,
      parents: [],
      type,
    }),
    [user, type]
  )

  return saved ? (
    <Redirect
      to={{
        pathname: '/register',
        search: qs.stringify(params),
        state: {done: numberOfUsers === 1},
      }}
    />
  ) : (
    <>
      <Link
        to={{
          search: qs.stringify({
            ...params,
            ...(numberOfUsers > 1 ? {type} : {}),
          }),
          pathname: '/register',
        }}
      >
        {t('Tilbage')}
      </Link>
      <Box mb={3}>
        <H1 textAlign="center">{t('createUser', {name: user.firstName})}</H1>
      </Box>
      <Formik
        isInitialValid={validationSchema.isValidSync(initialValues)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({isValid}) => (
          <Form>
            <Flex>
              <Box mr={3} flex="1">
                <Field
                  small
                  name="firstName"
                  placeholder={`${t('Fornavn')} *`}
                  component={Input}
                />
              </Box>
              <Box flex="1">
                <Field
                  small
                  name="surname"
                  placeholder={`${t('Efternavn')} *`}
                  component={Input}
                />
              </Box>
            </Flex>
            {!isChild && (
              <Field
                small
                name="email"
                type="email"
                placeholder={`${t('Email')} *`}
                validate={validateEmail}
                component={Input}
              />
            )}
            {!isChild && (
              <>
                <Field
                  small
                  name="zip"
                  placeholder={`${t('Postnummer')} *`}
                  component={Input}
                />
                <Field
                  small
                  name="password"
                  type="password"
                  placeholder={`${t('Kode')} *`}
                  component={Input}
                />
                <Field
                  small
                  name="passwordConfirmation"
                  type="password"
                  placeholder={`${t('Kode gentaget')} *`}
                  component={Input}
                />
              </>
            )}
            <Button small block type="submit" disabled={!isValid}>
              {numberOfUsers === 1 ? t('Godkend!') : t('Gem!')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

const enhancer = compose(
  withRouterParams,
  connect(
    createStructuredSelector({
      user: getCurrent,
      numberOfUsers: getNumberOfUsers,
      emails: getAllEmails,
    }),
    {save}
  )
)

export default enhancer(New)
