import 'react-app-polyfill/stable'
import React from 'react'
import {render} from 'react-dom'
import WebFont from 'webfontloader'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {ThemeProvider, createGlobalStyle} from 'styled-components'
import {normalize} from 'polished'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import qs from 'qs'
import App from 'app/components/App'
import {Signup} from 'signup/components'
import {store as signupStore, persistor} from 'signup'
import store from './store'
import theme from 'lib/style/theme'
import Cookies from './Cookies'
import './i18n'
import 'lib/yup'

WebFont.load({
  google: {
    families: ['Roboto:300,400,700'],
  },
})

const GlobalStyle = createGlobalStyle`
  ${normalize()};

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 14px;

    @media (min-width: 1025px) {
      font-size: 14px;
    }
  }

  body.modal-open {
    overflow: hidden;
  }
`

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot remove a child from a different parent',
          child,
          this
        )
      }
      return child
    }
    return originalRemoveChild.apply(this, arguments)
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        )
      }
      return newNode
    }
    return originalInsertBefore.apply(this, arguments)
  }
}

render(
  <ThemeProvider theme={theme}>
    <Cookies>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route
            path="/registrer"
            render={({location: {search}}) => (
              <Redirect to={{pathname: 'register', search}} />
            )}
          />
          <Route
            path="/register"
            render={(props) => {
              const {clubToken, code} = qs.parse(props.location.search, {
                ignoreQueryPrefix: true,
              })

              return clubToken || code ? (
                <Redirect
                  to={`/invitation${qs.stringify(
                    {code, clubToken},
                    {addQueryPrefix: true}
                  )}`}
                />
              ) : (
                <Provider store={signupStore}>
                  <PersistGate persistor={persistor} loading={null}>
                    <Signup {...props} />
                  </PersistGate>
                </Provider>
              )
            }}
          />
          <Route
            render={(props) => (
              <Provider store={store}>
                <App {...props} />
              </Provider>
            )}
          />
        </Switch>
      </Router>
    </Cookies>
  </ThemeProvider>,
  document.getElementById('root')
)
