import logoDa from 'images/logo-white-da.png'
import logoEn from 'images/logo-white-en.png'
import logoDe from 'images/logo-white-de.png'
import logoPl from 'images/logo-white-pl.png'
import logoCz from 'images/logo-white-cz.png'
import logoEs from 'images/logo-white-es.png'
import logoSk from 'images/logo-white-sk.png'
import logoNo from 'images/logo-white-no.png'
import logoDaDark from 'images/logo-dark-da.png'
import logoEnDark from 'images/logo-dark-en.png'
import logoDeDark from 'images/logo-dark-de.png'
import logoEsDark from 'images/logo-dark-es.png'
import logoPlDark from 'images/logo-dark-pl.png'
import logoCzDark from 'images/logo-dark-cz.png'
import logoSkDark from 'images/logo-dark-sk.png'
import logoNoDark from 'images/logo-dark-no.png'

const logos = {
  da: {
    white: logoDa,
    dark: logoDaDark,
  },
  en: {
    white: logoEn,
    dark: logoEnDark,
  },
  de: {
    white: logoDe,
    dark: logoDeDark,
  },
  es: {
    white: logoEs,
    dark: logoEsDark,
  },
  pl: {
    white: logoPl,
    dark: logoPlDark,
  },
  cz: {
    white: logoCz,
    dark: logoCzDark,
  },
  sk: {
    white: logoSk,
    dark: logoSkDark,
  },
  no: {
    white: logoNo,
    dark: logoNoDark,
  },
}

const getLogo = (language, color = 'white') => logos[language][color]

export default getLogo
