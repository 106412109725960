import React, {Suspense, useEffect, useState, lazy} from 'react'
import styled from 'styled-components'
import {includes} from 'lodash'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Redirect, Switch, Route} from 'react-router-dom'
import Content from './Content'
import Sidebar from './Sidebar'
import Directions from 'Directions'
import Members from 'members/components/Members'
import {Messages} from 'messages/components'
import Activities from 'activities/components/Activities'
import Download from 'app/components/Download'
import isPhone from 'lib/isPhone'
import {getHasClubs} from 'clubs/selectors'
import {getReady, getAuthenticated, getSponsor} from 'authentication'
import {getIsMember} from 'user'
import Sponsor from './Sponsor'
import {
  Login,
  ForgotPassword,
  Logout,
  ResetPassword,
  Payments,
  CreateUser,
  Onboarding,
  Overview,
  CreateClub,
  Settings,
  Signup,
} from 'scenes'

const MemberPerspective = lazy(() =>
  import('memberPerspective/components/MemberPerspective')
)

const MemberChat = lazy(() =>
  import('memberPerspective/components/MemberChat')
)

const Container = styled.div`
  font-family: ${(props) => props.theme.fontStack};
`

const App = ({
  authenticated,
  ready,
  hasClubs,
  sponsor: {show, path},
  location: {pathname},
  isMember,
}) => {
  if (isPhone && !includes(pathname, 'invitation')) {
    return <Directions />
  }

  if (show) {
    return <Sponsor path={path} />
  }

  const [prevPathname, setPrevPathname] = useState(null)

  useEffect(() => {
    if (prevPathname !== pathname) {
      window.scrollTo(0, 0)
    }

    setPrevPathname(pathname)
  }, [prevPathname, pathname])

  return ready ? (
    <Container>
      {authenticated && hasClubs && <Sidebar isMember={isMember} />}

      <Content sidebarVisible={authenticated && hasClubs}>
        <Suspense fallback={<p>Loading ...</p>}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/create" component={CreateUser} />
            <Route exact path="/download" component={Download} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route exact path="/reset" component={ResetPassword} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/create-club" component={CreateClub} />

            {authenticated && (
              <>
                <Route exact path="/my-page" component={MemberPerspective} />
                <Route exact path="/chat" component={MemberChat} />
                <Route path="/overview" component={Overview} />
                <Route path="/members" component={Members} />
                <Route path="/messages" component={Messages} />
                <Route path="/activities" component={Activities} />
                <Route path="/payments" component={Payments} />
                <Route path="/settings" component={Settings} />
                <Route path="/onboarding" component={Onboarding} />
              </>
            )}

            <Route path="/invitation" component={Signup} />
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
        </Suspense>
      </Content>
    </Container>
  ) : null
}

const enhancer = connect(
  createStructuredSelector({
    authenticated: getAuthenticated,
    sponsor: getSponsor,
    hasClubs: getHasClubs,
    ready: getReady,
    isMember: getIsMember,
  })
)

export default enhancer(App)
