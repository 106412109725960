import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {createStructuredSelector} from 'reselect'
import {noop, get, includes} from 'lodash'
import Row from './Row'
import {withRouterParams} from 'lib/hoc'
import {update, fetchRelations, updateImport} from 'members/actions'
import {getIsGroupLeader} from 'user'
import {getHasOneAdministrator} from 'members/selectors'
import {getActive as getActiveGroup} from 'groups/selectors'
import GroupsModal from './GroupsModal'
import RelationsModal from './RelationsModal'
import UpdateTypeConfirmModal from './UpdateTypeConfirmModal'

class Body extends Component {
  state = {
    groupsModalVisible: false,
    groupsModalId: null,
    relationsModalVisible: false,
    relationsModalId: null,
    confirmModalVisible: false,
    confirmTypeId: null,
    confirmMemberId: null,
    confirmChange: noop,
    rejectChange: noop,
  }

  showGroupsModal = (id) =>
    this.setState({
      groupsModalVisible: true,
      groupsModalId: id,
    })

  hideGroupsModal = () =>
    this.setState({groupsModalVisible: false, groupsModalId: null})

  showRelationsModal = (id) =>
    this.setState({
      relationsModalVisible: true,
      relationsModalId: id,
    })

  hideRelationsModal = () =>
    this.setState({relationsModalVisible: false, relationsModalId: null})

  resetConfirmModal = () =>
    this.setState({
      confirmModalVisible: false,
      confirmTypeId: null,
      confirmMemberId: null,
      confirmChange: noop,
      rejectChange: noop,
    })

  updateMember = ({userId, type}) => {
    const {updateMember} = this.props

    return new Promise((resolve, reject) => {
      this.setState({
        confirmModalVisible: true,
        confirmTypeId: type,
        confirmChange: resolve,
        confirmMemberId: userId,
        rejectChange: reject,
      })
    })
      .then(({addGroups, removeGroups}) =>
        updateMember({
          values: {id: userId, memberType: type, addGroups, removeGroups},
        })
      )
      .then(this.resetConfirmModal)
      .catch(this.resetConfirmModal)
  }

  render() {
    const {
      members,
      toggleBulk,
      bulk,
      fetchRelations,
      hasOneAdministrator,
      openMember,
      inactive,
      group,
      isGroupLeader,
      showJoinModal,
    } = this.props
    const {
      confirmModalVisible,
      confirmTypeId,
      confirmChange,
      confirmMemberId,
      rejectChange,
      groupsModalVisible,
      groupsModalId,
      relationsModalVisible,
      relationsModalId,
    } = this.state

    return (
      <Fragment>
        {groupsModalVisible && (
          <GroupsModal
            hide={this.hideGroupsModal}
            memberId={groupsModalId}
            inactive={inactive}
            showJoinModal={showJoinModal}
          />
        )}
        {relationsModalVisible && (
          <RelationsModal
            hide={this.hideRelationsModal}
            memberId={relationsModalId}
          />
        )}
        {confirmModalVisible && (
          <UpdateTypeConfirmModal
            memberId={confirmMemberId}
            accept={confirmChange}
            reject={rejectChange}
            typeId={confirmTypeId}
            inactive={inactive}
          />
        )}
        {members
          .filter((member) => member && member.id)
          .map((member) => (
            <Row
              key={member.id}
              checked={includes(bulk, member.id.toString())}
              toggleBulk={toggleBulk}
              updateMember={this.updateMember}
              showGroupsModal={this.showGroupsModal}
              showRelationsModal={this.showRelationsModal}
              fetchRelations={fetchRelations}
              hasOneAdministrator={hasOneAdministrator}
              open={`${member.id}` === openMember}
              isLeader={includes(get(group, 'leaders', []), member.id)}
              groupName={get(group, 'title')}
              isGroupLeader={isGroupLeader}
              {...member}
            />
          ))}
      </Fragment>
    )
  }
}

const enhancer = compose(
  withRouterParams,
  connect(
    createStructuredSelector({
      hasOneAdministrator: getHasOneAdministrator,
      group: getActiveGroup,
      isGroupLeader: getIsGroupLeader,
    }),
    {
      updateMember: update.requested,
      fetchRelations: fetchRelations.requested,
      updateImport: updateImport.requested,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      inactive: ownProps.params.status === 'inactive',
    })
  )
)

export default enhancer(Body)
