import React from 'react'
import IconBase from './IconBase'

const Help = props => (
  <IconBase viewBox="0 0 15.53 28.93" {...props}>
    <g class="st0">
      <path
        class="st1"
        d="m3.93 20.37c-0.13-0.34-0.23-0.72-0.3-1.14s-0.11-0.82-0.11-1.2c0-0.6 0.06-1.15 0.18-1.64s0.29-0.96 0.53-1.4 0.53-0.85 0.88-1.25 0.76-0.79 1.23-1.18c0.5-0.42 0.93-0.8 1.3-1.16s0.69-0.72 0.95-1.07 0.46-0.73 0.59-1.11 0.2-0.81 0.2-1.28c0-0.4-0.07-0.78-0.21-1.12s-0.38-0.65-0.64-0.9-0.6-0.44-0.98-0.59-0.81-0.21-1.28-0.21c-1.02 0-2.07 0.21-3.16 0.63s-2.13 1.07-3.11 1.93v-5.88c1-0.6 2.1-1.05 3.28-1.35s2.41-0.45 3.67-0.45c1.21 0 2.34 0.13 3.38 0.4s1.95 0.69 2.72 1.26 1.38 1.31 1.82 2.2 0.65 1.96 0.65 3.21c0 0.79-0.09 1.51-0.27 2.16s-0.45 1.25-0.8 1.83-0.79 1.12-1.31 1.65-1.12 1.07-1.8 1.63c-0.46 0.38-0.86 0.73-1.2 1.05s-0.63 0.65-0.86 0.97-0.4 0.66-0.52 1.02-0.18 0.76-0.18 1.22c0 0.31 0.04 0.63 0.13 0.96s0.2 0.6 0.34 0.82h-5.12zm2.85 8.56c-1.04 0-1.91-0.31-2.6-0.94-0.69-0.65-1.04-1.42-1.04-2.3 0-0.91 0.34-1.67 1.04-2.29s1.56-0.92 2.6-0.92c1.03 0 1.88 0.31 2.56 0.92 0.68 0.6 1.02 1.36 1.02 2.29 0 0.94-0.33 1.71-1 2.32-0.68 0.61-1.54 0.92-2.58 0.92z"
      />
    </g>
  </IconBase>
)

export default Help
