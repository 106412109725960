import React, {Component} from 'react'
import {compose} from 'recompose'
import qs from 'qs'
import {withTranslation} from 'react-i18next'
import {Box, Flex, H2, Text, Link, Hr} from 'components'
import {LogoWithTagline, Person} from 'components/icons'
import {withRouterParams} from 'lib/hoc'
import ClubInformation from './ClubInformation'
import Context from '../Context'
import LoginForm from './LoginForm'
import RegistrationForm from './RegistrationForm'

const methods = {
  NEW_USER: '0',
  EXISTING_USER: '1',
}

class Details extends Component {
  storeClubToken = () => {
    localStorage.setItem('SIGNUP_CLUB_TOKEN', this.props.params.clubToken)
  }

  render() {
    const {
      params: {method = methods.NEW_USER},
      location: {pathname},
      club,
      clubToken,
      setStep,
      t,
    } = this.props
    const isCreatingNewUser = method === methods.NEW_USER

    return (
      <>
        <Box mt={3}>
          <LogoWithTagline width="auto" height="50" />
        </Box>

        <Box mx="auto">
          <ClubInformation {...club} />

          {clubToken && (
            <Flex mt={2} flexDirection="column">
              <Text center>
                {isCreatingNewUser
                  ? t('Allerede bruger i MinForening?')
                  : t('Har du ikke en bruger?')}{' '}
                <Link
                  primary
                  to={{
                    pathname,
                    search: qs.stringify({
                      clubToken,
                      method: isCreatingNewUser
                        ? methods.EXISTING_USER
                        : methods.NEW_USER,
                    }),
                  }}
                >
                  {isCreatingNewUser ? t('Log ind her') : t('Opret en her')}
                </Link>
              </Text>
            </Flex>
          )}

          <Box my={3}>
            <Hr />
          </Box>
        </Box>

        <Flex mb={4} alignItems="center" mx="auto">
          <Box mr={2}>
            <Person fill="secondary" size={24} />
          </Box>
          <H2>
            {isCreatingNewUser ? t('Opret bruger på') : t('Log ind på')}{' '}
            <strong>{t('MinForening')}</strong>
          </H2>
        </Flex>

        <Box width={1}>
          {isCreatingNewUser ? (
            <RegistrationForm setStep={setStep} initialValues={{clubToken}} />
          ) : (
            <LoginForm setStep={setStep} initialValues={{clubToken}} />
          )}
        </Box>
      </>
    )
  }
}

const enhancer = compose(withRouterParams, withTranslation())

export default enhancer((props) => (
  <Context.Consumer>
    {(value) => <Details {...props} {...value} />}
  </Context.Consumer>
))
