import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {
  CopyToClipboardButton,
  Modal,
  Text,
  Box,
  Flex,
  Button,
  InputWithButton,
} from 'components'
import {getLinksArray, getUserLinks} from 'links/selectors'
import {deactivate} from 'links/actions'
import {create} from 'links/actions'
import LinksList from './LinksList'
import {withTranslation} from 'react-i18next'

class InviteModal extends Component {
  state = {
    isCopied: false,
    showActive: false,
  }

  componentDidMount() {
    const {userLinks, create} = this.props

    if (userLinks.length === 0) {
      create()
    }
  }

  componentDidUpdate(prevProps) {
    const {links} = this.props
    if (prevProps.links.length !== links.length) {
      this.setState({isCopied: false})
    }
  }

  setIsCopied = () => this.setState({isCopied: true})

  setShowActive = (showActive) => this.setState({showActive})

  hide = (event) => {
    event.stopPropagation()

    this.props.hide()
  }

  renderOverview = () => {
    const {userLinks, deactivate, t} = this.props
    const link = userLinks.length > 0 ? userLinks[0] : undefined
    const url = link
      ? `https://${window.location.host}/invitation?clubToken=${link.token}`
      : undefined

    return (
      <Fragment>
        <InputWithButton
          disabled
          value={url}
          buttonProps={{
            danger: true,
            onClick: link ? () => deactivate(link.id) : undefined,
          }}
        >
          {t('Nulstil')}
        </InputWithButton>

        <Box mb={3} width={1}>
          <CopyToClipboardButton
            small
            block
            value={url}
            callback={this.setIsCopied}
          >
            {this.state.isCopied ? t('Kopieret!') : t('Kopier')}
          </CopyToClipboardButton>
        </Box>

        <Button small success block onClick={() => this.setShowActive(true)}>
          {t('Oversigt over aktive links')}
        </Button>
      </Fragment>
    )
  }

  renderActive = () => (
    <LinksList links={this.props.links} deactivate={this.props.deactivate} />
  )

  render() {
    const {showActive} = this.state
    const {t} = this.props

    return (
      <Modal
        width={showActive ? 500 : 400}
        hide={this.hide}
        back={showActive ? () => this.setShowActive(false) : undefined}
        title={
          showActive ? t('Aktive links i foreningen') : t('Tilmeldingslink')
        }
      >
        <Flex flexDirection="column" p={3}>
          <Text secondary center mb={3}>
            {t(
              'Send linket til medlemmer eller indsæt link på hjemmeside og socialt medie'
            )}
          </Text>
          {showActive ? this.renderActive() : this.renderOverview()}
        </Flex>
      </Modal>
    )
  }
}

const enhancer = connect(
  createStructuredSelector({
    userLinks: getUserLinks,
    links: getLinksArray,
  }),
  {
    create: create.requested,
    deactivate: deactivate.requested,
  }
)

export default withTranslation()(enhancer(InviteModal))
