import React from 'react'
import IconBase from './IconBase'

const Clock = props =>
  <IconBase viewBox="-724 -1097 60.092 59.656" {...props}>
    <g transform="translate(-1207 -1272.69)">
      <path d="M256.145,423.227c-.5.123-1,.233-1.5.325a2.416,2.416,0,1,0,.88,4.751c.6-.111,1.2-.241,1.79-.389a2.416,2.416,0,0,0-1.168-4.688Z" transform="translate(262.971 -193.023)"/>
      <path d="M413.161,140.968a2.416,2.416,0,0,0,4.587-1.519c-.191-.577-.4-1.156-.628-1.72a2.415,2.415,0,1,0-4.486,1.794C412.823,140,413,140.483,413.161,140.968Z" transform="translate(123.819 56.857)"/>
      <path d="M323.385,394.312c-.426.281-.866.554-1.309.809a2.416,2.416,0,1,0,2.414,4.185c.527-.3,1.05-.627,1.558-.963a2.416,2.416,0,0,0-2.663-4.031Z" transform="translate(203.581 -167.556)"/>
      <path d="M432.464,209.632a2.416,2.416,0,1,0-4.828.19c.02.509.025,1.027.013,1.536a2.416,2.416,0,1,0,4.83.107C432.493,210.857,432.488,210.24,432.464,209.632Z" transform="translate(110.605 -5.06)"/>
      <path d="M381.675,344.553a2.415,2.415,0,0,0-3.383.483c-.307.409-.63.812-.96,1.2a2.416,2.416,0,0,0,.273,3.405c.058.049.116.094.177.137a2.416,2.416,0,0,0,3.228-.41c.395-.464.78-.946,1.146-1.434A2.416,2.416,0,0,0,381.675,344.553Z" transform="translate(154.911 -124.152)"/>
      <path d="M417.443,279.463a2.415,2.415,0,0,0-3.028,1.582c-.153.486-.322.975-.5,1.454a2.416,2.416,0,0,0,4.514,1.72c.217-.569.418-1.15.6-1.728A2.416,2.416,0,0,0,417.443,279.463Z" transform="translate(122.694 -67.795)"/>
      <path d="M25.577,56.55a24.944,24.944,0,0,1-6.2-1.969c-.023-.012-.044-.026-.068-.037-.463-.218-.925-.451-1.373-.7l-.007,0a25.77,25.77,0,0,1-2.4-1.5,25.208,25.208,0,0,1,.105-41.29l.087-.06a25.251,25.251,0,0,1,28.3-.227l-1.887,2.727c-.525.759-.2,1.312.717,1.229l8.2-.734a1.351,1.351,0,0,0,1.222-1.766l-2.2-7.932c-.246-.889-.877-1-1.4-.237L46.775,6.785A29.833,29.833,0,0,0,24.914,2.137q-1.16.2-2.288.488l-.015,0c-.029.007-.059.017-.087.024A29.787,29.787,0,0,0,6.007,13.722c-.035.041-.071.082-.1.126-.137.185-.273.373-.406.562-.218.31-.432.627-.638.944-.026.038-.045.077-.068.116A29.782,29.782,0,0,0,.031,33.037c0,.02,0,.041,0,.062.027.606.075,1.22.141,1.825,0,.039.012.076.019.115.067.608.152,1.218.258,1.827A29.813,29.813,0,0,0,8.855,53.034l.031.032.011.01a30.439,30.439,0,0,0,3.848,3.216A29.785,29.785,0,0,0,24.724,61.3a2.416,2.416,0,0,0,.853-4.755Z" transform="translate(483 174.003)"/>
      <path d="M207.942,83.2a1.955,1.955,0,0,0-1.955,1.955v19.473l17.809,9.206a1.954,1.954,0,0,0,1.795-3.472l-15.7-8.114V85.153A1.954,1.954,0,0,0,207.942,83.2Z" transform="translate(303.621 103.021)"/>
      </g>
  </IconBase>

export default Clock
