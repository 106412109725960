import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose} from 'recompose'
import get from 'lodash/get'
import {useTranslation} from 'react-i18next'
import {withRouterParams} from 'lib/hoc'
import {Button} from 'components'
import {download, downloadInactive} from 'members'
import {getActive as getActiveGroup} from 'groups/selectors'
import {getActiveClubName, getActive} from 'clubs/selectors'

const DownloadButton = ({
  download,
  downloadInactive,
  group,
  activeClub,
  clubName,
  params: {key, direction},
}) => {

  const [t] = useTranslation();

  const handleClick = () => {

    if (group == "inactive") {

      new Promise((resolve, reject) =>
      downloadInactive({clubId: get(activeClub, 'id'), resolve, reject})
        ).then((data) => {
          const blob = new Blob(['\ufeff', data], {
            type: 'text/csv;charset=utf-8;',
          })
          const link = document.createElement('a')
          link.download = group
            ? `${clubName}_${group}.csv`
            : `${clubName}.csv`
          link.href = window.URL.createObjectURL(blob)
          link.click()
        })
    }
    else {
      new Promise((resolve, reject) =>
        download({groupId: get(group, 'id'), key, direction, resolve, reject}))
          .then((data) => {
          const blob = new Blob(['\ufeff', data], {
            type: 'text/csv;charset=utf-8;',
          })
          const link = document.createElement('a')
          link.download = group
            ? `${clubName}_${group.title}.csv`
            : `${clubName}.csv`
          link.href = window.URL.createObjectURL(blob)
          link.click()
      })
    }
  }

  return (

    <Button primary onClick={handleClick}>
        {t('Eksporter medlemmer')}
      </Button>

  )
}

const enhancer = compose(
  withRouterParams,
  connect(
    createStructuredSelector({
      activeClub: getActive,
      clubName: getActiveClubName,
      group: getActiveGroup,
    }),
    {download, downloadInactive}
  ),
)

export default enhancer(DownloadButton)