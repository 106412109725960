import React from 'react'
import {Flex, Box} from '@rebass/grid'
import {isMobile} from 'react-device-detect'
import {DownloadTheApp, Image, Text, H2} from 'components'
import {LogoWithTagline} from 'components/icons'
import Context from '../Context'
import {useTranslation} from 'react-i18next'

const Download = () => {
  const [t] = useTranslation()

  return (
    <Context.Consumer>
      {({club: {name, imageUrl}}) => (
        <React.Fragment>
          <Box my={3}>
            <LogoWithTagline width="auto" height="50" />
          </Box>

          <Box mb={2} mx="auto">
            <Text>{t('Du er nu medlem af')}</Text>
          </Box>

          <Flex mx="auto" mb={4}>
            <Box mr={2}>
              <Image round width="30" height="30" src={imageUrl} />
            </Box>
            <H2>
              <strong>{name}</strong>
            </H2>
          </Flex>

          <Box mx="auto" mb={4} width={250}>
            <Text center>
              {t(
                'Du kan nu hente MinForening appen og deltage i aktiviteter i din forening.'
              )}
            </Text>
          </Box>

          {isMobile ? (
            <React.Fragment>
              <Box mx="auto" mb={3}>
                <Text>
                  <small>
                    <strong>{t('Download vores applikation her')}</strong>
                  </small>
                </Text>
              </Box>

              <Box mx="auto" style={{maxWidth: '100%'}}>
                <DownloadTheApp />
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box mx="auto" width={250}>
                <Text center>
                  {t(
                    'Download MinForening appen på din smartphone eller tablet ved at søge på MinForening i App Store eller Google Play.'
                  )}
                </Text>
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Context.Consumer>
  )
}
export default Download
