import {useTranslation} from 'react-i18next'
//const [t] = useTranslation()

// Payment status
export const ACTIVE = 0
export const OVERDUE = 1
export const PREVIOUS = 2

// UserPaymentStatus
export const NOT_DEFINED = 0
export const NOT_PAID = 1
export const PAID = 2
export const PAID_EXTERNALLY = 3
export const CANCELLED = 4
export const REJECTED = 5
export const RESERVED = 6
export const REFUNDED = 7
export const ADMIN_APPROVED = 8
export const ADMIN_APPROVED_REJECTED = 9
export const ADMIN_APPROVED_PAIDEXTERNALLY = 10


export const MESSAGES = {
  [PAID]: 'Betalt via {paymentMethod}',
  [NOT_PAID]: 'Ikke betalt',
  [CANCELLED]: 'Annulleret af administrator',
  [REJECTED]: 'Annulleret af bruger',
  [PAID_EXTERNALLY]: 'Betalt eksternt',
  [REFUNDED]: 'Refunderet',
  [ADMIN_APPROVED]: 'Godkendt af administrator',
  [ADMIN_APPROVED_REJECTED]: 'Annulleret, Godkendt af administrator',
  [ADMIN_APPROVED_PAIDEXTERNALLY]: 'Betalt eksternt, Godkendt af administrator'
}


