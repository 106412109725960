import React, {useCallback} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {compose} from 'recompose'
import {useTranslation} from 'react-i18next'
import {Formik, Form, Field} from 'formik'
import Yup from 'lib/yup'
import {Button, FormikInput as Input, Loading} from 'components'
import {forgotPassword} from 'authentication'

const initialValues = {
  email: '',
}

const validationSchema = Yup.object({
  email: Yup.string().required().email(),
})

const ForgotPasswordForm = ({forgotPassword, history}) => {
  const handleSubmit = useCallback(
    ({email}) => {
      new Promise((resolve, reject) => {
        forgotPassword({email, resolve, reject})
      }).then(() => {
        history.push({
          pathname: '/login',
          state: {
            passwordLinkSent: true,
          },
        })
      })
    },
    [forgotPassword, history]
  )

  const [t] = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({isValid, isSubmitting}) => (
        <Form>
          <Field
            white
            small
            border="1px solid"
            borderColor="inactive"
            name="email"
            type="email"
            placeholder="Email"
            component={Input}
          />

          <Button
            disabled={!isValid || isSubmitting}
            primary
            small
            block
            type="submit"
          >
            {isSubmitting ? <Loading size={16} /> : t('Nulstil adgangskode')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const enhancer = compose(
  withRouter,
  connect(null, {forgotPassword: forgotPassword.requested})
)

export default enhancer(ForgotPasswordForm)
