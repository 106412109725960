import React from 'react'
import { Flex } from '@rebass/grid'
import { pure } from 'recompose'
import { Row, Cell } from 'components/Table'
import ActivityRow from './Row'

const Group = ({ name, activities = [], showShowModal }) => (
  <Flex flexDirection="column">
    <Row justifyContent="center">
      <Cell secondary>{name}</Cell>
    </Row>
    {activities.length > 0 &&
      activities.map(activity => (
        <ActivityRow
          key={activity.id}
          {...activity}
        />
      ))}
  </Flex>
)

export default pure(Group)
