const steps = {
  CHOOSE: 1,
  CONVENTUS: 2,
  CSV: 3,
  DONE: 4,

  1: 'CHOOSE',
  2: 'CONVENTUS',
  3: 'CSV',
  4: 'DONE',
}

export default steps
