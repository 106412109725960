import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {useTranslation} from 'react-i18next'
import {LinkButton, Text, Box, Flex} from 'components'
import {Invite, Add, Help, Integrations, Settings} from 'components/icons'
import {getIsGroupLeader} from 'user'
import {getActive} from 'clubs'
import countryCodes from 'lib/countryCodes'

const Shortcuts = ({isGroupLeader, club, ...props}) => {
  const [t] = useTranslation()
  return (
    <Box {...props}>
      <Box mb={2}>
        <Text secondary bold>
          {t('Genveje')}
        </Text>
      </Box>
      <Flex flexWrap="wrap">
        <LinkButton
          bold
          success
          to={{
            pathname: '/members',
            search: 'key=firstName&direction=asc',
            state: {invite: true},
          }}
          mr={2}
          mb={2}
        >
          <Flex alignItems="center">
            <Box mr={2}>
              <Invite fill="white" size={20} />
            </Box>
            {t('Inviter')}
          </Flex>
        </LinkButton>
        <LinkButton
          bold
          primary
          to={{pathname: '/activities', state: {create: true}}}
          mr={2}
          mb={2}
        >
          <Flex alignItems="center">
            <Box mr={2}>
              <Add fill="white" size={20} />
            </Box>
            {t('Ny aktivitet')}
          </Flex>
        </LinkButton>
        <LinkButton
          bold
          purple
          external
          target="_blank"
          to="https://minforening.dk/support/"
          mr={2}
          mb={2}
        >
          <Flex alignItems="center">
            <Box mr={2}>
              <Help fill="white" size={16} />
            </Box>
            {t('Support')}
          </Flex>
        </LinkButton>
        <LinkButton
          bold
          danger
          external
          target="_blank"
          to="https://minforening.dk/gratis/"
          mr={2}
          mb={2}
        >
          <Flex alignItems="center">
            <Box mr={2}>
              <Help fill="white" size={16} />
            </Box>
            {t('GDPR')}
          </Flex>
        </LinkButton>
        {!isGroupLeader && club?.countryCode === countryCodes.da && (
          <LinkButton bold warning to="/settings/integrations" mr={2} mb={2}>
            <Flex alignItems="center">
              <Box mr={2}>
                <Integrations fill="white" size={16} />
              </Box>
              {t('Integrationer')}
            </Flex>
          </LinkButton>
        )}
        <LinkButton
          external
          bold
          secondary
          to="https://minforening.dk/implementering/"
          target="_blank"
          mr={2}
          mb={2}
        >
          <Flex alignItems="center">
            <Box mr={2}>
              <Settings fill="white" size={16} />
            </Box>
            {t('Implementering')}
          </Flex>
        </LinkButton>
      </Flex>
    </Box>
  )
}
const enhancer = connect(
  createStructuredSelector({isGroupLeader: getIsGroupLeader, club: getActive})
)

export default enhancer(Shortcuts)
