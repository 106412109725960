import React, {useContext} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose} from 'recompose'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {Logout} from 'components/icons'
import {Text, Image, Link, Box, Flex, Button} from 'components'
import AdminMenu from './Menu'
import MemberMenu from './Menu/MemberMenu'
import ClubDropdown from './ClubDropdown'
import MemberDropdown from './MemberDropdown'
import {Context as CookieContext} from 'Cookies'
import LanguageDropdown from './LanguageDropdown'
import {getCompanyName, getTldLocale} from 'app/selectors'
import getLogo from 'lib/getLogo'
import getBadge from 'lib/getBadge'

const Container = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'space-between',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props) => props.theme.sidebarWidth};
  height: 100vh;
  background: ${(props) => props.theme.colors.secondary};
`

const Sidebar = ({companyName, tldLocale, isMember}) => {
  const {showModal} = useContext(CookieContext)

  const [t, i18n] = useTranslation()

  return (
    <Container>
      <Box>
        <Box m={4}>
          <Image src={getLogo(tldLocale)} style={{maxWidth: '100%'}} />
        </Box>
        <Box m={4}>
          <ClubDropdown />
        </Box>
        {!isMember && (
          <Box my={3}>
            <AdminMenu />
          </Box>
        )}
        <Box m={4} my={3}>
          <MemberDropdown />
        </Box>

        <MemberMenu />

        <Box my={3} mx={4}>
          <Link to="/logout">
            <Flex alignItems="center">
              <Box mr={2}>
                <Logout size={20} fill="white" />
              </Box>
              <Text light>{t('Log ud')}</Text>
            </Flex>
          </Link>
        </Box>

        <Box mx={4} my={3}>
          <LanguageDropdown />
        </Box>
      </Box>

      <Box m={4} mt={2}>
        <Box mb={2}>
          <Text light small>
            {t(
              "Find dine og din families aktiviteter på {{companyName}} app'en",
              {companyName}
            )}
          </Text>
        </Box>

        <Box>
          <Box>
            <Link
              external
              to="https://itunes.apple.com/dk/app/minforening/id1149003433?l=da&mt=8"
            >
              <Image src={getBadge('appStore', i18n.language)} width={150} />
            </Link>
            <Link
              external
              to="https://play.google.com/store/apps/details?id=dk.minforening.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <Image src={getBadge('googlePlay', i18n.language)} width={150} />
            </Link>
          </Box>

          <Button transparent small mt={2} onClick={showModal}>
            <Text light>{t('Cookies')}</Text>
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

const enhancer = compose(
  withRouter,
  connect(
    createStructuredSelector({
      companyName: getCompanyName,
      tldLocale: getTldLocale,
    })
  )
)

export default enhancer(Sidebar)
