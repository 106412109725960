import React, {useCallback} from 'react'
import {Formik, Field, Form} from 'formik'
import {useToggle} from 'lib/hooks'
import {Modal, Button, Box, TextArea} from 'components'
import {useTranslation} from 'react-i18next'

const ApproveWithNoteButton = ({
  approve,
  memberId,
  userPaymentId,
  status,
  ...props
}) => {
  const [t] = useTranslation()
  const [isOpen, open, close] = useToggle()
  const handleSubmit = useCallback(
    ({note}) => {
      approve(memberId, {userPaymentId, internalNote: note, status})
      close()
    },
    [close, approve, memberId, userPaymentId]
  )

  return (
    <>
      {isOpen && (
        <Modal title={`${t('Godkend betaling')}`} hide={close}>
          <Box p={3}>
            <Formik onSubmit={handleSubmit} initialValues={{note: ''}}>
              <Form>
                <Field
                  last
                  name="note"
                  component={TextArea}
                  placeholder={`${t('Note')}...`}
                />
                <Button small block mt={3} type="submit">
                  {t('Godkend')}
                </Button>
              </Form>
            </Formik>
          </Box>
        </Modal>
      )}
      <Button onClick={open} {...props}>
        {t('Godkend')}
      </Button>
    </>
  )
}

export default ApproveWithNoteButton
