import React from 'react'
import IconBase from './IconBase'

const Weight = props =>
  <IconBase viewBox="-852 -1086.17 80 45.17" {...props}>
    <g transform="translate(-991.45 -1280.1)">
      <g transform="translate(139.46 193.93)" >
        <path transform="translate(-405.65 -191.18)" d="m484.23 206.86a1.587 1.587 0 0 0 -1.376 1.727v10.342a1.412 1.412 0 1 0 2.751 0v-10.342a1.585 1.585 0 0 0 -1.375 -1.727z" />
        <path transform="translate(0 -191.18)" d="m1.381 206.86a1.587 1.587 0 0 0 -1.381 1.727v10.338a1.591 1.591 0 0 0 1.376 1.727 1.583 1.583 0 0 0 1.374 -1.727v-10.342a1.585 1.585 0 0 0 -1.372 -1.723z" />
        <path transform="translate(-72.137 -108.81)" d="M90.46,108.815a4.921,4.921,0,0,0-4.591,5.192v34.773a4.626,4.626,0,1,0,9.181,0v-13.2h33.4v13.2a4.626,4.626,0,1,0,9.181,0V114.007a4.626,4.626,0,1,0-9.181,0v13.2h-33.4v-13.2a4.918,4.918,0,0,0-4.591-5.2Z" />
        <path transform="translate(-355.48 -145.25)" d="m427.06 152.19h-0.449a3.454 3.454 0 0 0 -3.454 3.454v24.367a3.454 3.454 0 0 0 3.454 3.454h0.449a3.454 3.454 0 0 0 3.454 -3.454v-24.367a3.454 3.454 0 0 0 -3.454 -3.454z" />
        <path transform="translate(-23.225 -145.25)" d="M31.564,152.188h-.451a3.454,3.454,0,0,0-3.454,3.454v24.367a3.454,3.454,0,0,0,3.454,3.454h.449a3.454,3.454,0,0,0,3.454-3.454V155.642a3.454,3.454,0,0,0-3.452-3.454Z" />
      </g>
    </g>
  </IconBase>

export default Weight
