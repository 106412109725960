import React from 'react'
import {noop, sumBy, sortBy, toLower} from 'lodash'
import {useTranslation} from 'react-i18next'
import {Row, Cell} from 'components/Table'
import PaymentRow from './Row'
import {NotificationBadge, Flex, Box} from 'components'
import price from 'lib/price'
import {PAID} from 'payments/constants'
import countryCodes from 'lib/countryCodes'

const sumAmount = (payers) =>
  payers.reduce((acc, payer) => {
    if (payer.activityPayment) {
      return acc + payer.activityPayment.amount
    } else {
      return (
        acc + (payer.status === PAID ? payer.amountAfterFees : payer.amount)
      )
    }
  }, 0)

const sumMPAmout = (payers) =>
  sumBy(
    payers.filter(({status}) => status === PAID),
    'amountAfterFees'
  )

const Group = ({
  id,
  activity,
  name,
  payers,
  showAmount,
  showNoteModal,
  renderStatus,
  countryCode,
  filteredIsAccepted,
}) => {
  const [t] = useTranslation()

console.log(payers)

  return (
    <Flex flexDirection="column">
      <Row justifyContent="center" noHover midHeader >
        <Cell secondary bold >
          <Box mr={2}>{name}</Box>
          {name === t('Kræver godkendelse') ? (
            <NotificationBadge value={payers.length} warning />
          ) : (
            <div>
              {showAmount && (
                <>
                  ({price(sumAmount(payers))}
                  {countryCode === countryCodes.da &&
                    ` heraf ${price(sumMPAmout(payers))} via MobilePay`}
                  )
                </>
              )}
            </div>
          )}
        </Cell>
      </Row>
          {payers.length > 0 &&
        sortBy(
          payers.filter(({user}) => !!user),
          ({user: {firstName}}) => toLower(firstName)
        ).map((payer) => (
          <PaymentRow 
            {...payer}
            filteredIsAccepted={filteredIsAccepted}
            key={payer.id}
            paymentId={id}
            renderStatus={renderStatus}
            showNoteModal={showNoteModal}
          />
        ))}
    </Flex>
  )
}

Group.defaultProps = {
  renderStatus: noop,
  renderActions: noop,
  showAmount: false,
}

export default Group
