import React, {useCallback} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Modal, Box} from 'components'
import {create} from 'sponsors/actions'
import Form from './Form'

const initialValues = {
  title: '',
  logoBase64: null,
  url: '',
}

const CreateModal = ({create, hide}) => {
  const handleSubmit = useCallback(
    (values, {setSubmitting}) => {
      new Promise((resolve, reject) => {
        create({resolve, reject, ...values})
      }).then(hide)
    },
    [create, hide]
  )

  const [t] = useTranslation()

  return (
    <Modal title={t('Opret sponsor')} hide={hide}>
      <Box p={3}>
        <Form initialValues={initialValues} onSubmit={handleSubmit} />
      </Box>
    </Modal>
  )
}

const enhancer = connect(null, {create: create.requested})

export default enhancer(CreateModal)
