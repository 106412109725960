import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Overview from './Overview'
import MobilepayAgreement from './Mobilepay/Agreement'
import KevinAgreement from './Kevin/KevinAgreement'

const PaymentMethods = ({ modules, integrations, match: { path } }) => (
  <Switch>
    <Route exact path={`${path}`} component={Overview} />
    <Route path={`${path}/mobilepay`} component={MobilepayAgreement} />
    <Route path={`${path}/kevin`} component={KevinAgreement} />
    <Route render={() => <Redirect to={path} />} />
  </Switch>
)

export default PaymentMethods
