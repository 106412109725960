import React, {useState, useCallback} from 'react'
import {connect} from 'react-redux'
import {Modal, Box, Button} from 'components'
import {create} from 'members/actions'
import {sendInvitationEmails} from 'clubs/actions'
import Done from './Done'
import CreateForm from './CreateForm'
import {useTranslation} from 'react-i18next'

const CreateModal = ({create, hide, sendInvitationEmails, showImportModal}) => {
  const [t] = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [step, setStep] = useState(1)
  const [newMemberId, setNewMemberId] = useState(null)

  const handleSubmit = useCallback(
    (values, {setFieldError}) => {
      setIsSubmitting(true)

      new Promise((resolve, reject) => create({values, resolve, reject}))
        .then((members) => {
          setStep(2)
          setNewMemberId(members[0].userId)
        })
        .catch((error) => setFieldError('email', error))
    },
    [setIsSubmitting, create, setStep, setNewMemberId]
  )

  const sendInvitationEmail = useCallback(() => {
    sendInvitationEmails([`i${newMemberId}`])
    hide()
  }, [hide, newMemberId, sendInvitationEmails])

  return (
    <Modal title={t('Opret medlem')} hide={hide} width={350}>
      <Box bg="secondaryLight" p={3}>
        {step === 1 && (
          <>
            <CreateForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />
            <Button
              purple
              small
              block
              mt={3}
              onClick={() => {
                hide()
                showImportModal()
              }}
              disabled={isSubmitting}
            >
              {t('Importer fra CSV')}
            </Button>
          </>
        )}
        {step === 2 && (
          <Done hide={hide} sendInvitationEmail={sendInvitationEmail} />
        )}
      </Box>
    </Modal>
  )
}

const enhancer = connect(null, {
  create: create.requested,
  sendInvitationEmails: sendInvitationEmails.requested,
})

export default enhancer(CreateModal)
