// import React, {useEffect, useState} from 'react';
// import {Box, H1, Text, Loading} from 'components';
// import {useTranslation} from 'react-i18next';

import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Redirect} from 'react-router-dom'
import {compose} from 'recompose'
import qs from 'qs'
import {get} from 'lodash'
import {useTranslation} from 'react-i18next'
import {
  withAuthenticationRequirement,
  withOnboardingRestriction,
  withRouterParams,
} from 'lib/hoc'
import {Box, H1, Text, Loading} from 'components'
import {
  getNumberOfMembers,
  getMissingInvitations,
  getInvitedInactiveMembers,
  getPendingMembersArray,
  getGroupRequestMembers,
  fetch as fetchMembers,
} from 'members'
import {getIsGroupLeader} from 'user'
import {fetch as fetchGroups, getActive as getActiveGroup} from 'groups'
import {getActive} from 'clubs'
import {getCompanyName} from 'app/selectors'

import GroupDropdown from '../../../members/components/GroupDropdown'

const Export = ({
  fetchGroups,
  fetchMembers,
  params: {status, key, direction, openMember, group: groupId},
}) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
      fetchGroups()
      fetchMembers()
    }, [fetchGroups, fetchMembers])

    return !key || !direction || !groupId ? (

      <>
      {/* <Loading /> */}
      <Redirect
        to={{
          pathname: '/settings/Export',
          search: qs.stringify({
            key: key || 'firstName',
            direction: direction || 'asc',
            group: groupId || 'all',
            openMember,
            status,
          }),
        }}
      />
      
      </>
    ) : (
        <>
       <H1>{t('Export')}</H1>

       <GroupDropdown/>
      
        </>
    )
}


const enhancer = compose(
  withAuthenticationRequirement,
  withOnboardingRestriction,
  withRouterParams,
  connect(
    createStructuredSelector({
      companyName: getCompanyName,
      numberOfMembers: getNumberOfMembers,
      missing: getMissingInvitations,
      invited: getInvitedInactiveMembers,
      pendingClub: getPendingMembersArray,
      groupRequests: getGroupRequestMembers,
      active: getActive,
      group: getActiveGroup,
      isGroupLeader: getIsGroupLeader,
    }),
    {
      fetchGroups: fetchGroups.requested,
      fetchMembers: fetchMembers.requested,
    }
  )
)

export default enhancer(Export)


// const enhancer = connect(createStructuredSelector({
//   companyName: getCompanyName,
//   active: getActive,
//   group: getActiveGroup,
// }), {
//   fetchGroups: fetchGroups.requested,
//   fetchMembers: fetchMembers.requested,
// })

// export default enhancer(Export)