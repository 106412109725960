import {handleActions, combineActions} from 'redux-actions'
import {omit, get} from 'lodash'
import * as actions from './actions'

const initialState = {
  entities: {},
  isFetching: false,
  isFetched: false,
}

const reducer = handleActions(
  {
    [actions.fetch.requested]: (state) => ({
      ...state,
      isFetching: true,
    }),

    [actions.fetch.succeeded]: (
      state,
      {
        payload: {
          entities: {payments, activityPayments},
        },
      }
    ) => ({
      ...state,
      isFetching: false,
      isFetched: true,
      entities: {
        ...payments,
        ...activityPayments,
      },
    }),

    [combineActions(
      actions.create.succeeded,
      actions.addPayers.succeeded,
      actions.update.succeeded
    )]: (state, {payload}) => {
      const id = get(payload, 'result')

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: get(payload, `entities.payments[${id}]`),
        },
      }
    },

    [actions.remove.succeeded]: (state, {payload: id}) => ({
      ...state,
      entities: omit(state.entities, id),
    }),

    [combineActions(
      actions.removePayers.succeeded,
      actions.approve.succeeded,
      actions.reject.succeeded,
      actions.updatePayer.succeeded
    )]: (
      state,
      {
        payload: {
          result,
          entities: {payments},
        },
      }
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [result]: payments[result],
      },
    }),

    [actions.sendReminder.succeeded]: (state, {payload: {id, member}}) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          pending: state.entities[id].pending.map((payer) =>
            payer.user.userId === member
              ? {...payer, lastReminderSent: new Date()}
              : payer
          ),
        },
      },
    }),

    [actions.fetchActivityPayers.succeeded]: (
      state,
      {payload: {id, completed, approvable, pending}}
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          completed,
          approvable,
          pending,
        },
      },
    }),
  },
  initialState
)

export default reducer
