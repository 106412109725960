import React from 'react'
import IconBase from './IconBase'

const Goblet = props =>
  <IconBase viewBox="-850.739 -1010.299 70.739 65.299" {...props}>
    <g transform="translate(-989.95 -1134.4)">
      <path transform="translate(139.21 105.88)" d="M69.545,30.35a3.935,3.935,0,0,0-2.891-1.191H54.417V25.076a6.82,6.82,0,0,0-6.8-6.8H23.125a6.82,6.82,0,0,0-6.8,6.8v4.081H4.089A3.935,3.935,0,0,0,1.2,30.348,3.935,3.935,0,0,0,0,33.24v5.442a12.1,12.1,0,0,0,1.764,6.079,20.253,20.253,0,0,0,4.761,5.526,26.439,26.439,0,0,0,7.354,4.145,28.833,28.833,0,0,0,9.161,1.892,22.654,22.654,0,0,0,4.037,4.037,7.938,7.938,0,0,1,2.231,3.082,10.774,10.774,0,0,1,.615,3.8,5.873,5.873,0,0,1-1.3,3.868,5.065,5.065,0,0,1-4.143,1.574,9,9,0,0,0-5.675,1.934,5.929,5.929,0,0,0-2.485,4.87v2.721a1.309,1.309,0,0,0,1.36,1.36H53.052a1.307,1.307,0,0,0,1.36-1.36V79.492a5.927,5.927,0,0,0-2.487-4.868A9,9,0,0,0,46.25,72.69a5.064,5.064,0,0,1-4.143-1.574,5.876,5.876,0,0,1-1.295-3.868,10.774,10.774,0,0,1,.615-3.8,7.962,7.962,0,0,1,2.231-3.082A22.656,22.656,0,0,0,47.7,56.325a28.841,28.841,0,0,0,9.163-1.892,26.45,26.45,0,0,0,7.354-4.145,20.269,20.269,0,0,0,4.76-5.526,12.1,12.1,0,0,0,1.764-6.079V33.242a3.941,3.941,0,0,0-1.192-2.892ZM9.459,45.568Q5.442,42,5.442,38.682V34.6H16.323A37.571,37.571,0,0,0,19.47,50.372a21.155,21.155,0,0,1-10.01-4.8Zm55.835-6.886q0,3.316-4.017,6.886a21.16,21.16,0,0,1-10.01,4.8,37.571,37.571,0,0,0,3.15-15.77H65.3v4.081Z" />
    </g>
  </IconBase>

export default Goblet
