import React, {useCallback} from 'react'
import {useField, Formik, Form as FormikForm, Field} from 'formik'
import {useTranslation} from 'react-i18next'
import {useDropzone} from 'react-dropzone'
import Yup from 'lib/yup'
import {Loading, Box, Text, Button, FormikInput as Input} from 'components'

const validationSchema = Yup.object({
  title: Yup.string().required(),
  url: Yup.string().required(),
  logo: Yup.string(),
  logoBase64: Yup.mixed().when('logo', {
    is: (value) => !value,
    then: Yup.mixed().required(),
  }),
})

const ImageInput = ({placeholder}) => {
  const [{value}, {error}, {setValue}] = useField('logoBase64')
  const handleDrop = useCallback(
    (files) => {
      setValue(
        Object.assign(files[0], {preview: URL.createObjectURL(files[0])})
      )
    },
    [setValue]
  )
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDropAccepted: handleDrop,
  })
  const [t] = useTranslation()

  return (
    <Box mb={2}>
      <Box
        {...getRootProps({
          p: 2,
          border: '1px dashed',
          borderColor: 'secondary',
          borderRadius: 3,
          width: 400,
        })}
      >
        <input {...getInputProps()} />
        {value || placeholder ? (
          <img
            alt="Sponsor preview"
            style={{width: '100%', height: '100%'}}
            src={(value && value.preview) || placeholder}
          />
        ) : (
          <Text>{t('Vælg fil')}</Text>
        )}
      </Box>
      {error && (
        <Text small danger mt={2}>
          {error}
        </Text>
      )}
    </Box>
  )
}

const Form = ({initialValues, onSubmit, isEdit}) => {
  const [t] = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({isSubmitting, isValid}) => (
        <FormikForm>
          <Field small name="title" placeholder={t('Navn')} component={Input} />
          <Field small name="url" placeholder={t('Link')} component={Input} />
          <ImageInput placeholder={initialValues && initialValues.logo} />
          <Button block small disabled={isSubmitting || !isValid}>
            {isSubmitting ? <Loading size={16} /> : t(isEdit ? 'Gem' : 'Opret')}
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}

export default Form
