import appStoreDa from 'images/badges/app-store-da.png'
import appStoreDe from 'images/badges/app-store-de.png'
import appStoreEn from 'images/badges/app-store-en.png'
import appStorePl from 'images/badges/app-store-pl.png'
import appStoreEs from 'images/badges/app-store-es.png'
import appStoreNo from 'images/badges/app-store-no.png'
import appStoreCz from 'images/badges/app-store-cz.png'
import appStoreSk from 'images/badges/app-store-sk.png'
import googlePlayDa from 'images/badges/google-play-da.png'
import googlePlayDe from 'images/badges/google-play-de.png'
import googlePlayEn from 'images/badges/google-play-en.png'
import googlePlayEs from 'images/badges/google-play-es.png'
import googlePlayNo from 'images/badges/google-play-no.png'
import googlePlayCz from 'images/badges/google-play-cz.png'
import googlePlayPl from 'images/badges/google-play-pl.png'
import googlePlaySk from 'images/badges/google-play-sk.png'

const badges = {
  appStore: {
    da: appStoreDa,
    de: appStoreDe,
    en: appStoreEn,
    es: appStoreEs,
    no: appStoreNo,
    cz: appStoreCz,
    pl: appStorePl,
    sk: appStoreSk,
  },
  googlePlay: {
    da: googlePlayDa,
    de: googlePlayDe,
    en: googlePlayEn,
    es: googlePlayEs,
    no: googlePlayNo,
    cz: googlePlayCz,
    pl: googlePlayPl,
    sk: googlePlaySk,
  },
}

const getBadge = (brand, language) => badges[brand][language]

export default getBadge
