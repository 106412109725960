import React, {useEffect, useState, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose} from 'recompose'
import {useTranslation} from 'react-i18next'
import {isAfter, isBefore, startOfToday} from 'date-fns'
import {debounce, includes, toLower} from 'lodash'
import Table, {Cell, Row} from 'components/Table'
import {Text, Button, DialogueModal, ButtonWithProtectedAction, Box, Flex, Input,} from 'components'
import Group from './Group'
import NoteModal from './NoteModal'
import SendReminderButton from './SendReminderButton'
import Context from '../Context'
import {MESSAGES, NOT_PAID, PAID, PAID_EXTERNALLY, CANCELLED, REJECTED, REFUNDED, ADMIN_APPROVED, ADMIN_APPROVED_REJECTED, ADMIN_APPROVED_PAIDEXTERNALLY} from 'payments/constants'
import {sendReminder, removePayers, approve, reject} from 'payments/actions'
import {withToggle} from 'lib/hoc'
import format from 'lib/format'
import ApproveWithNoteButton from './ApproveWithNoteButton'
import countryCodes from 'lib/countryCodes'
import {getActive, getActiveCurrency} from 'clubs'

const predicate = ({user: {firstName, surname}}, _query) => {
  const query = toLower(_query)

  return (
    includes(toLower(firstName), query) || includes(toLower(surname), query)
  )
}

const PayersTable = ({
  id,
  removePayers,
  approve: approveAction,
  reject: rejectAction,
  sendReminder: sendReminderAction,
  isApprovable,
  isOverdue,
  isAccepted,
  showDialogueModal,
  dialogueModalVisible,
  hideDialogueModal,
  activity,
  club,
  currency,
  paymentStartDate,
}) => {
  const [t] = useTranslation()
  const sendReminder = useCallback(
    (member) => {
      sendReminderAction({
        id,
        member,
      })
    },
    [id, sendReminderAction]
  )

  const remove = useCallback(
    (members) => {
      removePayers({
        id,
        members,
      })
    },
    [id, removePayers]
  )

  const approve = useCallback(
    (member, {userPaymentId, status, internalNote} = {}) => {
      let currentStatus = status,
          newStatus = currentStatus;
      console.log(internalNote)

      if (currentStatus === NOT_PAID) {
        newStatus = ADMIN_APPROVED
        approveAction({id, member, userPaymentId, status: newStatus, internalNote})
      }
      else if (currentStatus === PAID_EXTERNALLY) {
        newStatus = ADMIN_APPROVED_PAIDEXTERNALLY
        approveAction({id, member, userPaymentId, status: newStatus, internalNote})
      }
      else if (currentStatus === REJECTED) {
        newStatus = ADMIN_APPROVED_REJECTED
        approveAction({id, member, userPaymentId, status: newStatus, internalNote})
      }
      else {
        approveAction({id, member, userPaymentId, status: newStatus, internalNote})
      }
    },
    [id, approveAction]
  )

  const reject = useCallback(
    (member, {userPaymentId} = {}) => {
      rejectAction({id, member, userPaymentId})
    },
    [id, rejectAction]
  )

  const [query, setQuery] = useState('')

  const handleChange = useCallback(
    ({target: {value}}) => {
      setQuery(value)
    },
    [setQuery]
  )

  const [filteredIsApprovable, setFilteredIsApprovable] = useState(isApprovable)
  const [filteredIsOverdue, setFilteredIsOverdue] = useState(isOverdue)
  const [filteredIsAccepted, setFilteredIsAccepted] = useState(isAccepted)

  const filter = useCallback(
    debounce((query) => {
      if (query.trim() === '') {
        setFilteredIsApprovable(isApprovable)
        setFilteredIsOverdue(isOverdue)
        setFilteredIsAccepted(isAccepted)
      } else {
        setFilteredIsApprovable(
          isApprovable.filter((payer) => predicate(payer, query))
        )
        setFilteredIsOverdue(
          isOverdue.filter((payer) => predicate(payer, query))
        )
        setFilteredIsAccepted(
          isAccepted.filter((payer) => predicate(payer, query))
        )
      }
    }, 300),
    [
      isApprovable,
      setFilteredIsApprovable,
      isOverdue,
      setFilteredIsOverdue,
      isAccepted,
      setFilteredIsAccepted,
    ]
  )

  useEffect(() => {
    filter(query)
  }, [query, filter])

  return activity &&
    filteredIsApprovable.length +
      filteredIsOverdue.length +
      filteredIsAccepted.length ===
      0 ? (
    <Text secondary mx="auto">
      {t(
        'Betalinger i denne betalingsaktivitet bliver vist, så snart de foretages af medlemmer.'
      )}
    </Text>
  ) : (
    <>
      <Flex justifyContent="flex-end">
        <Input
          small
          width={200}
          value={query}
          onChange={handleChange}
          placeholder={`${t('Søg på navn')} ...`}
        />
      </Flex>
      <Flex flexDirection="column">
        <Table>
          <Row header>
            <Cell width={45} justifyContent="center" alignItems="center" />
            <Cell width={1 / 7} alignItems="center">
              <Text light bold>
                {t('Fornavn')}
              </Text>
            </Cell>

            <Cell width={1 / 7} alignItems="center">
              <Text light bold>
                {t('Efternavn')}
              </Text>
            </Cell>

            <Cell width={1 / 12} alignItems="center">
              <Text light bold>
                {t('Status')}
              </Text>
            </Cell>
            <Cell width={1 / 8} alignItems="center" >
              <Text light bold>
                {club?.countryCode === countryCodes.da
                  ? t('Beløb (MobilePay)')
                  : currency}
              </Text>
            </Cell>
            <Cell width={1 / 12} alignItems="center">
              <Text light bold>
                {t('Note')}
              </Text>
            </Cell>
            <Cell width={140} alignItems="center">
              {!activity && (
                <Text light bold>
                  {t('Betalingsstatus')}
                </Text>
              )}
            </Cell>
          </Row>

          {filteredIsApprovable.length > 0 && (
            <Group
              id={id}
              activity={activity}
              name={t('{{count}} kræver godkendelse', {
                count: filteredIsApprovable.length,
              })}
              payers={filteredIsApprovable}
              countryCode={club?.countryCode}
              renderStatus={({status, note, memberId, id: userPaymentId, clubPaymentMethodName}) => (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flex="1"
                >
                  <Flex alignItems="center">
                    <Text
                      small
                      bold
                      {...{
                        danger: status === CANCELLED || status === REJECTED,
                        warning: status === PAID_EXTERNALLY || status === PAID,
                      }}
                    >
                      {isBefore(startOfToday(), paymentStartDate) &&
                        t(MESSAGES[status]).replace("{paymentMethod}", clubPaymentMethodName)}
                      {isAfter(startOfToday(), paymentStartDate) &&
                        t(MESSAGES[status]).replace("{paymentMethod}", clubPaymentMethodName)}
                    </Text>
                    {status !== PAID && note && (
                      <Box ml={2}>
                        <Context.Consumer>
                          {({showNoteModal}) => (
                            <Button
                              tiny
                              {...{
                                danger:
                                  status === CANCELLED || status === REJECTED,
                                warning:
                                  status === PAID_EXTERNALLY || status === PAID,
                              }}
                              onClick={() => showNoteModal(note)}
                            >
                              {t('Læs mere')}
                            </Button>
                          )}
                        </Context.Consumer>
                      </Box>
                    )}
                  </Flex>
                  <Flex alignItems="center">
                    <Box mr={2}>
                      <Button danger tiny onClick={() => reject(memberId)}>
                        {t('Afvis')}
                      </Button>
                    </Box>
                    <Box>
                      <ApproveWithNoteButton
                        tiny
                        success
                        mr={2}
                        approve={approve}
                        status={status}
                        memberId={memberId}
                        userPaymentId={userPaymentId}
                      />
                      {/* <Button success tiny onClick={() => approve(memberId)}>
                        {t('Accepter')}
                      </Button> */}
                    </Box>
                  </Flex>
                </Flex>
              )}
            />
          )}

          {filteredIsOverdue.length > 0 && (
            <Group
              id={id}
              activity={activity}
              name={t('{{count}} afventer betaling', {
                count: filteredIsOverdue.length,
              })}
              payers={filteredIsOverdue}
              countryCode={club?.countryCode}
              renderStatus={({ status, approved, memberId, id: userPaymentId, lastReminderSent, requestDate,
              }) => (
                <Flex alignItems="center" width={1}
                justifyContent="space-between">
                  <Box mr={3} flex="0 0 140px">
                    <Text
                      bold
                      small
                      {...{
                        danger: isBefore(requestDate, startOfToday()),
                        secondary: !isBefore(requestDate, startOfToday()),
                      }}
                    >
                      {isAfter(startOfToday(), paymentStartDate) && (
                        <>
                          {isBefore(requestDate, startOfToday())
                            ? t('Betalingsfrist overskredet')
                            : t(MESSAGES.NOT_PAID)}
                        </>
                      )}
                    </Text>
                  </Box>
                  <Flex
                    alignItems="center"
                    width={1}
                    justifyContent="space-between"
                  >                   
                      <Box>
                        <ApproveWithNoteButton
                          tiny
                          success
                          mr={2}
                          status={status}
                          approve={approve}
                          memberId={memberId}
                          userPaymentId={userPaymentId}
                        />
                      </Box>
                      <Box>
                        <ButtonWithProtectedAction
                          danger
                          tiny
                          accept={() => remove(memberId)}
                        >
                          {t('Annuller')}
                        </ButtonWithProtectedAction>
                      </Box>
  
                    <Flex width={1} justifyContent="flex-end" flexWrap="wrap">
                      {!(approved && status === NOT_PAID) &&
                        (!paymentStartDate ||
                          isBefore(paymentStartDate, new Date())) && (
                          <Box mr={2}>
                            <SendReminderButton
                              tiny
                              lastReminderSent={lastReminderSent}
                              showDialogue={showDialogueModal}
                              sendReminder={() => sendReminder(memberId)}
                            />
                          </Box>
                        )}
                    </Flex>
                  </Flex>
                </Flex>
              )}
            />
          )}

          {filteredIsAccepted.length > 0 && ( 
            <Group
              id={id}
              activity={activity}
              name={t('{{count}} afviklede betalinger', {
                count: filteredIsAccepted.length,
              })}
              payers={filteredIsAccepted}
              filteredIsAccepted={filteredIsAccepted}
              //countryCode={club?.countryCode}
              showAmount
              
              renderStatus={({status, note, paymentDate, approved, requestDate, isInactiveUser, memberId, id: userPaymentId, clubPaymentMethodName}) => (
                
                <Flex alignItems="center" inactive>
                  <Text bold success small>
                  {isBefore(startOfToday(), paymentStartDate) && (
                      <>
                        {t(MESSAGES[status]).replace("{paymentMethod}", clubPaymentMethodName)}                              
                        {paymentDate && ( 
                          ` (${format(paymentDate, 'D/M/YYYY')})`
                        )} 
                        {!paymentDate && approved && (
                              `(${format(approved, 'D/M/YYYY')})`
                        )}
                      </>
                    )}
                    {isAfter(startOfToday(), paymentStartDate) && (
                      <>
                       {t(MESSAGES[status]).replace("{paymentMethod}", clubPaymentMethodName)}                              
                        {paymentDate && ( 
                          ` (${format(paymentDate, 'D/M/YYYY')})`
                        )} 
                        {!paymentDate && approved && (
                          `(${format(approved, 'D/M/YYYY')})`
                        )}
                      </>
                    )}

                    {(status === PAID && !paymentStartDate) && (
                      <>
                        {t(MESSAGES[status]).replace("{paymentMethod}", clubPaymentMethodName)}
                      </>
                    )}
                  </Text>                  
                  {(status === null || status === undefined) && (
                    <Text secondary bold>
                      {t(MESSAGES[status]).replace("{paymentMethod}", clubPaymentMethodName)}
                    </Text>
                  )}
                  {((status === REJECTED && activity) || status === REFUNDED) && (
                    <Text secondary bold>
                      {t(MESSAGES[status]).replace("{paymentMethod}", clubPaymentMethodName)}
                    </Text>
                  )}
                  {status !== PAID && note && (
                    <Box ml={2}>
                      <Context.Consumer>
                        {({showNoteModal}) => (
                          <Button
                            tiny
                            success
                            onClick={() => showNoteModal(note)}
                          >
                            {t('Læs mere')}
                          </Button>
                        )}
                      </Context.Consumer>
                    </Box>
                  )}
                </Flex>
              )}
            />
          )}
        </Table>

        {dialogueModalVisible && (
          <DialogueModal
            hide={hideDialogueModal}
            title={`${t('Slet betaling')}`}
          >
            <Text center>
              {t('Du kan højest sende én påmindelse pr person om dagen.')}
            </Text>
          </DialogueModal>
        )}

        <Context.Consumer>
          {({noteModalVisible, hideNoteModal, note}) =>
            noteModalVisible && <NoteModal hide={hideNoteModal} note={note} />
          }
        </Context.Consumer>
      </Flex>
    </>
  )
}

const enhancer = compose(
  connect(
    createStructuredSelector({
      club: getActive,
      currency: getActiveCurrency,
    }),
    {
      sendReminder: sendReminder.requested,
      removePayers: removePayers.requested,
      approve: approve.requested,
      reject: reject.requested,
    }
  ),
  withToggle(['dialogueModal'])
)

export default enhancer(PayersTable)
