import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {Box, Flex, Text, Button} from 'components'

const NoMembersInGroup = ({group, add, upload, invite}) => {
  const [t] = useTranslation()
  return (
    <React.Fragment>
      <Box my={4}>
        <Text secondary center>
          <Trans i18nKey="noMembersInGroup">
            Der er endnu ingen medlemmer i <strong>{group}</strong>
          </Trans>
        </Text>
      </Box>
      <Flex justifyContent="center">
        <Box>
          <Button small primary onClick={add}>
            {t('Tilføj medlemmer')}{' '}
          </Button>
        </Box>
        <Box mx={3}>
          <Button small primary onClick={upload}>
            {t('Importér nye medlemmer')}
          </Button>
        </Box>
        <Box>
          <Button small primary onClick={invite}>
            {t('Invitér nye medllemer')}
          </Button>
        </Box>
      </Flex>
    </React.Fragment>
  )
}
export default NoMembersInGroup
