import React, {useMemo, useCallback, useState} from 'react'
import {connect} from 'react-redux'
import {Formik, Form} from 'formik'
import Yup from 'lib/yup'
import {Box, Button, Modal, Loading} from 'components'
import {create} from 'messages/actions'
import Details from './Details'
import Groups from './Groups'
import {useTranslation} from 'react-i18next'

const validationSchema = Yup.object({
  title: Yup.string().required(),
  message: Yup.string().required(),
  allMembers: Yup.bool(),
  groupIds: Yup.array(),
})

const initialValues = {
  title: '',
  message: '',
  allMembers: false,
  groupIds: [],
}

const steps = {
  DETAILS: 0,
  GROUPS: 1,
}

const CreateModal = ({hide, create}) => {
  const [t] = useTranslation()
  const [step, setStep] = useState(steps.DETAILS)

  const handleNext = useCallback(
    (event) => {
      event.preventDefault()
      setStep(steps.GROUPS)
    },
    [setStep]
  )

  const handleSubmit = useCallback(
    (values, {setSubmitting}) => {
      new Promise((resolve, reject) => {
        create({values, resolve, reject})
      }).then(hide)
    },
    [create, hide]
  )

  const back = useMemo(
    () => (step === steps.GROUPS ? () => setStep(steps.DETAILS) : undefined),
    [step, setStep]
  )

  return (
    <Modal hide={hide} title={t('Opret besked')} back={back}>
      <Box m={3}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({isValid, isSubmitting, values, handleSubmit}) => (
            <Form>
              {step === steps.DETAILS && <Details />}
              {step === steps.GROUPS && <Groups />}

              {values.allMembers || step === steps.GROUPS ? (
                <Button
                  small
                  block
                  primary
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? <Loading size={20} /> : t('Godkend')}
                </Button>
              ) : (
                <Button
                  small
                  block
                  primary
                  disabled={!isValid || (!values.title && !values.message)}
                  type="button"
                  onClick={handleNext}
                >
                  {t('Vælg modtagere')}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  )
}

const enhancer = connect(null, {create: create.requested})

export default enhancer(CreateModal)
