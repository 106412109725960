import React from 'react'
import IconBase from './IconBase'

const People = props =>
  <IconBase viewBox="-980 -1003.155 87.997 58.155" {...props}>
    <g transform="translate(-1015.9 -1122.3)">
      <g transform="translate(35.9 119.1)" data-name="Group 426">
        <path transform="translate(-239.53 -119.1)" d="m283.32 119.1" data-name="Path 674"/>
        <path transform="translate(-159.04 -119.1)" d="M202.852,119.1H202.8a17.325,17.325,0,1,0,.054,0" data-name="Path 675"/>
        <path transform="translate(-58.865 -193.1)" d="M74.4,209a10.6,10.6,0,1,0,10.6,10.6A10.6,10.6,0,0,0,74.4,209" data-name="Path 677"/>
        <path transform="translate(-35.9 -299.28)" d="M51.43,338a18.643,18.643,0,0,0-15.53,8.471h0s2.829,4.227,15.756,4.227h.637l-.106-.141.548-.955a30.422,30.422,0,0,1,6.614-7.394c.548-.459,1.173-.937,1.822-1.4A17.864,17.864,0,0,0,51.43,338" data-name="Path 679"/>
        <path transform="translate(-323.83 -193.1)" d="M396.3,209a10.6,10.6,0,1,0,10.6,10.6A10.6,10.6,0,0,0,396.3,209" data-name="Path 681"/>
        <path transform="translate(-326.71 -299.28)" d="M399.175,338a18.024,18.024,0,0,0-9.975,2.937c.583.424,1.131.848,1.628,1.255a31.167,31.167,0,0,1,6.614,7.394l.548.955-.089.141c.478.018.991.018,1.509.018h0c12.469,0,15.3-4.227,15.3-4.227A18.68,18.68,0,0,0,399.175,338" data-name="Path 683"/>
        <path opacity="0" transform="translate(-111.71 -312.29)" d="M136.984,353.8c-.654.478-1.274.937-1.822,1.4a31.166,31.166,0,0,0-6.614,7.394l-.548.972.106.159c11.94-.141,14.663-4.227,14.663-4.227a18.793,18.793,0,0,0-5.785-5.7" data-name="Path 685"/>
        <path opacity="0" transform="translate(-300.78 -312.95)" d="M363.272,354.6a18.584,18.584,0,0,0-5.572,5.531s2.6,3.909,14.248,4.209l.089-.141-.548-.955a30.422,30.422,0,0,0-6.614-7.394c-.478-.407-1.026-.838-1.609-1.255" data-name="Path 687"/>
        <path transform="translate(-121.42 -292.94)" d="M165.216,330.3A30.525,30.525,0,0,0,139.8,344.167s4.616,6.933,25.788,6.933c20.411,0,25.027-6.933,25.027-6.933a30.506,30.506,0,0,0-25.4-13.867Z" data-name="Path 689"/>
      </g>
    </g>
  </IconBase>

export default People
