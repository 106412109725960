import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Formik, Form, Field} from 'formik'
import {Flex} from '@rebass/grid'
import {withTranslation} from 'react-i18next'
import {
  Box,
  Text,
  ImageInput,
  FormikInput as Input,
  // Switch,
  Button,
  TextArea,
} from 'components'
import {Camera} from 'components/icons'
import clubDefault from 'images/club-default.png'
import {getActive} from 'clubs/selectors'
import {update} from 'clubs/actions'

class General extends Component {
  state = {
    submitted: false,
  }

  handleSubmit = (values) =>
    new Promise((resolve) => this.props.update({...values, resolve})).then(() =>
      this.setState({submitted: true})
    )

  render() {
    const {club, t} = this.props
    const {submitted} = this.state

    return club ? (
      <Formik
        enableReinitialize
        onSubmit={this.handleSubmit}
        initialValues={club}
      >
        {() => (
          <Form>
            <Flex width={800} alignItems="flex-end">
              <Box flex="1" mr={4}>
                <Field
                  name="image"
                  component={ImageInput}
                  placeholder={club.imageUrl || clubDefault}
                  renderButton={({value}) => (
                    <Flex justifyContent="center" alignItems="center">
                      <Box mr={2}>
                        <Camera fill="purple" size={18} />
                      </Box>
                      <Text purple fontWeight={400}>
                        {club.imageUrl || value
                          ? t('Skift billede')
                          : t('Tilføj billede')}
                      </Text>
                    </Flex>
                  )}
                />
              </Box>
              <Box flex="1"></Box>
            </Flex>
            <Flex width={800} mt={3}>
              <Box mr={4} flex="1">
                <Box>
                  <Text small secondary>
                    {t('Navn')} *
                  </Text>
                </Box>
                <Field
                  small
                  white
                  noBorder
                  placeholder={t('Navngiv din forening, klub, hold')}
                  name="clubName"
                  component={Input}
                />
                <Flex>
                  <Box mr={3}>
                    <Text small secondary>
                      {t('Postnummer')} *
                    </Text>
                  </Box>
                  <Box flex="1">
                    <Field
                      small
                      white
                      noBorder
                      placeholder={`${t('Postnummer')} ...`}
                      name="zip"
                      component={Input}
                    />
                  </Box>
                </Flex>

                <Box>
                  <Text small secondary>
                    {t('Telefonnummer')}
                  </Text>
                </Box>
                <Field
                  small
                  white
                  noBorder
                  placeholder={`${t('Telefonnummer')} ...`}
                  name="phoneNumber"
                  component={Input}
                />

                <Box>
                  <Text small secondary>
                    {t('Hjemmeside')}
                  </Text>
                </Box>
                <Field
                  small
                  white
                  noBorder
                  placeholder={`${t('Hjemmeside')} ...`}
                  name="homePage"
                  component={Input}
                />
              </Box>

              <Box flex="1">
                <Box>
                  <Text small secondary>
                    {t('Adresse')}
                  </Text>
                </Box>
                <Field
                  small
                  white
                  noBorder
                  placeholder={`${t('Adresse')} ...`}
                  name="address"
                  component={Input}
                />

                <Box>
                  <Text small secondary>
                    {t('By')}
                  </Text>
                </Box>
                <Field
                  small
                  white
                  noBorder
                  placeholder={`${t('By')} ...`}
                  name="city"
                  component={Input}
                />

                <Box>
                  <Text small secondary>
                    {t('Beskrivelse')}
                  </Text>
                </Box>
                <Field
                  small
                  white
                  noBorder
                  name="description"
                  placeholder={`${t('Skriv en beskrivelse')} ...`}
                  component={TextArea}
                />
              </Box>
            </Flex>
            <Flex>
              <Box>
                <Button
                  bold
                  block
                  small
                  {...{success: submitted, primary: !submitted}}
                  type="submit"
                >
                  {submitted ? `${t('Gemt')}!` : t('Gem ændringer')}
                </Button>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    ) : null
  }
}

const enhancer = connect(
  createStructuredSelector({
    club: getActive,
  }),
  {
    update: update.requested,
  }
)

export default withTranslation()(enhancer(General))
