import React from 'react'
import {connect} from 'react-redux'
import {isToday, parse} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {Modal, Text} from 'components'
import {sendInvitationEmails} from 'clubs/actions'
import Sent from './Sent'
import NotSent from './NotSent'

const InvitationModal = ({
  hide,
  name,
  id,
  emailLastSent,
  sendInvitationEmails,
  invitationLink,
  dummy,
}) => {
  const [t] = useTranslation()
  return (
    <Modal hide={hide} title={t('Inaktiv bruger')} width={600}>
      {isToday(parse(emailLastSent)) ? (
        <Sent name={name} />
      ) : (
        <NotSent
          name={name}
          dummy={dummy}
          send={() => {
            sendInvitationEmails([id])
            hide()
          }}
        />
      )}
      {invitationLink && process.env.NODE_ENV === 'development' && (
        <Text p={2}>
          {process.env.REACT_APP_BASE_URL}/{invitationLink}
        </Text>
      )}
    </Modal>
  )
}
const enhancer = connect(null, {
  sendInvitationEmails: sendInvitationEmails.requested,
})

export default enhancer(InvitationModal)
