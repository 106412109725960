import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {createStructuredSelector} from 'reselect'
import {Switch, Route, Redirect} from 'react-router-dom'
import {withRouterParams} from 'lib/hoc'
import {getIsAuthenticated} from 'signup/user'
import {fetchDummies} from 'signup/actions'
import {Page, Box} from 'components'
import {LogoWithTagline} from 'components/icons'
import UserList from './UserList'
import User from './User'
import Invalid from './Invalid'
import Done from './Done'

export const Signup = ({
  fetchDummies,
  match: {path},
  params: {email, password},
  location: {pathname},
}) => {
  const [invalid, setInvalid] = useState(!email && !password)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    new Promise((resolve, reject) => {
      if (email && password) {
        return fetchDummies({email, password, resolve, reject})
      } else {
        throw Error()
      }
    })
      .then(() => setReady(true))
      .catch(() => {
        setReady(true)
        setInvalid(true)
      })
  }, [email, password, fetchDummies])

  return ready ? (
    invalid && pathname !== '/register/invalid' ? (
      <Redirect to="/register/invalid" />
    ) : (
      <Page>
        <Box my={4}>
          <LogoWithTagline height="50px" width="100%" />
        </Box>

        <Switch>
          <Route exact path={path} component={UserList} />
          <Route exact path={`${path}/done`} component={Done} />
          <Route exact path={`${path}/invalid`} component={Invalid} />
          <Route path={`${path}/:id`} component={User} />
        </Switch>
      </Page>
    )
  ) : null
}

const enhancer = compose(
  withRouterParams,
  connect(
    createStructuredSelector({
      isAuthenticated: getIsAuthenticated,
    }),
    {
      fetchDummies: fetchDummies.requested,
    }
  )
)

export default enhancer(Signup)
