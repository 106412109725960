import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Formik, Field} from 'formik'
import Yup from 'lib/yup'
import {Box, Modal, LabeledInput, LabeledTextArea, Button} from 'components'
import {create} from 'clubs/actions'

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  zip: Yup.string().required().min(4).max(8),
})

class CreateClubForm extends Component {
  
  handleSubmit = (values, {setErrors}) => {
    new Promise((resolve, reject) => {
      this.props.create({values, resolve, reject})
    })
      .then(this.props.hide)
      .catch(() => setErrors({title: 'Foreningen findes allerede'}))
  }

  render() {
    const {hide} = this.props

    return (
      <Modal hide={hide} title="Opret forening">
        <Box p={3}>
          <Formik
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <Field
                    name="title"
                    label="Foreningsnavn *"
                    component={LabeledInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    name="phoneNumber"
                    label="Telefonnummer"
                    component={LabeledInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    name="address"
                    label="Adresse"
                    component={LabeledInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field
                    name="zip"
                    label="Postnummer *"
                    component={LabeledInput}
                  />
                </Box>
                <Box mb={2}>
                  <Field name="city" label="By" component={LabeledInput} />
                </Box>
                <Box mb={2}>
                  <Field
                    name="homePage"
                    label="Hjemmeside"
                    component={LabeledInput}
                  />
                </Box>
                <Box mb={4}>
                  <Field
                    name="description"
                    label="Beskrivelse"
                    render={() => <LabeledTextArea label="Beskrivelse" />}
                  />
                </Box>

                <Button block small primary type="submit">
                  Opret forening
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    )
  }
}

const enhancer = connect(null, {
  create: create.requested,
})

export default enhancer(CreateClubForm)
