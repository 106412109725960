import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {NotificationBadge} from 'components'
import {getNumberOfActionsRequired} from 'payments/selectors'

const PaymentsNotificationBadge = ({payments}) =>
  payments > 0 ? <NotificationBadge value={payments} warning /> : null

const enhancer = connect(
  createStructuredSelector({
    payments: getNumberOfActionsRequired,
  })
)

export default enhancer(PaymentsNotificationBadge)
