import React, {useMemo} from 'react'
import {withRouter} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {includes} from 'lodash'
import Item from './Item'
import {
  Person,
  Association,
  People,
  Payment,
  Settings,
  Email,
} from 'components/icons'
import {Text} from 'components'

const PATH = '/my-page'

const BetaBadge = () => (
  <Text small px={1} py={0} bg="danger" color="white">
    Beta
  </Text>
)

const MemberMenu = ({location: {pathname}}) => {
  
  const [t] = useTranslation()
  
  const items = useMemo(
    () => [
      {
        name: t('Min side'),
        icon: Person,
        path: '/my-page' //,
        // badges: [ BetaBadge,
        //],
      },      
      { 
        name: t('Chat'),
        icon: Email,
        path: '/chat',
        badges: [ BetaBadge,
        ],
      },        
    ],
    [t, ]
  )

  return items.map((item) => (    
      <Item
        key={item.name}
        active={includes(
          item.path,
          pathname.split('/').filter((part) => !!part)[0]
        )}
        {...item}
      />    
  ))
}

export default withRouter(MemberMenu)

//Old way
// const active = useMemo(
  //   () => includes(PATH, pathname.split('/').filter((part) => !!part)[0]),
  //   [pathname]
  // )
  // return (
  //   <Item
  //     badges={[BetaBadge]}
  //     name={t('Min side')}
  //     active={active}
  //     path={PATH}
  //     icon={Person}
  //   />
  // )
