import React, {useState, useEffect, useCallback, useNavigate} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {compose} from 'recompose'
import {get, toLower, includes} from 'lodash'
import {useSearch} from 'react-use-search'
import {Input, Flex} from 'components'
import {getActive as getActiveGroup} from 'groups/selectors'
import {nGetSortedMembers} from 'members'
import {useToggle} from 'lib/hooks'
import {withRouterParams} from 'lib/hoc'
import BulkActions from './BulkActions'
import MemberTable from './MemberTable'
import NoMembersInGroup from './NoMembersInGroup'
import {useTranslation} from 'react-i18next'


const predicate = (member, value) => {
  const terms = toLower(value)
    .split(' ')
    .map((term) => term.trim())
    .filter((term) => !!term)
  const values = ['firstName', 'surname'].map((value) =>
    toLower(get(member, value))
  )

  return terms.some((term) => values.some((value) => includes(value, term)))
}

const MemberOverview = ({
  currentGroup,
  showImportModal,
  showInviteModal,
  openMember,
  params,
  members,
}) => {
  const [t] = useTranslation()

  const [bulk, setBulk] = useState([])
  const resetBulk = () => setBulk([])
  const toggleBulk = ({target: {value, checked}}) => {
    setBulk(checked ? [...bulk, value] : bulk.filter((id) => `${id}` !== value))
  }
  const toggleAll = ({target: {checked}}) =>
    setBulk(checked ? members.map(({id}) => id.toString()) : [])

  const [addModalVisible, showAddModal, hideAddModal] = useToggle()

  const [joinModalVisible, _showJoinModal, hideJoinModal] = useToggle()
  const showJoinModal = useCallback(
    (memberId) => {
      if (memberId) {
        setBulk([memberId])
      }

      _showJoinModal()
    },
    [setBulk, _showJoinModal]
  )

  const [filteredMembers, query, handleChange] = useSearch(members, predicate, {
    filter: true,
    debounce: 200,
  })

  useEffect(() => {
    resetBulk()
  }, [params.group])


  return (
    <>
      <Flex justifyContent="space-between" overflow="visible">
        <BulkActions
          bulk={bulk}
          resetBulk={resetBulk}
          showAddModal={showAddModal}
          hideAddModal={hideAddModal}
          addModalVisible={addModalVisible}
          showJoinModal={showJoinModal}
          hideJoinModal={hideJoinModal}
          joinModalVisible={joinModalVisible}
        />
        <Input
          small
          width={200}
          placeholder={`${t('Søg i medlemmer')} ...`}
          value={query}
          onChange={handleChange}
        />
      </Flex>
      {members.length > 0 ? (
        <MemberTable
          members={filteredMembers}
          bulk={bulk}
          toggleBulk={toggleBulk}
          toggleAll={toggleAll}
          openMember={openMember}
          showJoinModal={showJoinModal}
        />
      ) : (
        <NoMembersInGroup
          group={currentGroup.title}
          upload={showImportModal}
          invite={showInviteModal}
          add={showAddModal}
        />
      )}
    </>
  )
}

const enhancer = compose(
  withRouterParams,
  connect(
    createStructuredSelector({
      members: nGetSortedMembers,
      currentGroup: getActiveGroup,
    })
  )
)

export default enhancer(MemberOverview)
