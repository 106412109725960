import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  Foldable,
  Button,
  Box,
  Flex,
  ButtonWithProtectedAction,
} from 'components'
import MemberTable from '../../MemberTable'
import {accept, reject} from 'members/actions'

const ClubRequestTable = ({requests, accept, reject}) => {
  const [t] = useTranslation()
  return (
    <Foldable
      id="clubRequests"
      title={t('Foreningsanmodninger')}
      warning
      initialOpen
    >
      <MemberTable
        members={requests}
        renderActions={({id}) => (
          <Flex justifyContent="flex-end" flex="1">
            <Box mr={3}>
              <ButtonWithProtectedAction danger tiny accept={() => reject(id)}>
                {t('Afvis')}
              </ButtonWithProtectedAction>
            </Box>
            <Box>
              <Button tiny success onClick={() => accept(id)}>
                {t('Accepter')}
              </Button>
            </Box>
          </Flex>
        )}
      />
    </Foldable>
  )
}
const enhancer = connect(null, {
  accept: accept.requested,
  reject: reject.requested,
})

export default enhancer(ClubRequestTable)
