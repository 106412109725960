import React, {useMemo} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Redirect, Route, Switch} from 'react-router-dom'
import {compose} from 'recompose'
import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {getActive} from 'clubs/selectors'
import {getCompanyName} from 'app/selectors'
import {Flex, Box, Image, H1} from 'components'
import {
  Settings as SettingsIcon,
  Integrations as IntegrationsIcon,
  Payment as PaymentIcon,
  Association as AssociationIcon,
  People as PeopleIcon,
} from 'components/icons'
import {withAuthenticationRequirement} from 'lib/hoc'
import Tabs from './Tabs'
import General from './General'
import Integrations from './Integrations'
import PaymentMethods from './PaymentMethods'
import Sponsor from './Sponsor'
import Ambassador from './Ambassador'
import Onlime from './Onlime'
import Export from './Export'
import countryCodes from 'lib/countryCodes'

const Settings = ({club, match: {path}, companyName}) => {
  const [t] = useTranslation()

  const tabs = useMemo(
    () => [
      {
        id: 'general',
        name: t('Indstillinger'),
        icon: SettingsIcon,
      },
      ...(club?.countryCode === countryCodes.da
        ? [
            {
              id: 'paymentmethods',
              name: t('Betalingsmetoder'),
              icon: PaymentIcon,
            },
            {
              id: 'integrations',
              name: t('Integrationer'),
              icon: IntegrationsIcon,
            },
          ]
        : [{}

        ]),
      {
        id: 'sponsor',
        name: t('Sponsor'),
        icon: AssociationIcon,
      },
      {
        id: 'ambassador',
        name: t('Ambassadør'),
        icon: PeopleIcon,
      },
      {id: 'onlime', name: t('Gem filer i skyen')},
      // {id: 'export', name: t('Export af data')},
    ],
    [t, club]
  )

  return (
    <>
      <Helmet>
        <title>{t('Indstillinger | {{companyName}}', {companyName})}</title>
      </Helmet>

      <Flex alignItems="center" p={3} mb={3}>
        {club && (
          <>
            {club.imageUrl && (
              <Image round mr={3} width="85" height="85" src={club.imageUrl} />
            )}
            <Box flex="1">
              <H1 bold secondary>
                {club.clubName}
              </H1>
            </Box>
          </>
        )}
      </Flex>

      <Tabs tabs={tabs}>
        <Switch>
          <Route path={`${path}/general`} component={General} />
          <Route path={`${path}/integrations`} component={Integrations} />
          <Route path={`${path}/paymentMethods`} component={PaymentMethods} />
          <Route path={`${path}/sponsor`} component={Sponsor} />
          <Route path={`${path}/ambassador`} component={Ambassador} />
          <Route path={`${path}/onlime`} component={Onlime} />
          <Route path={`${path}/export`} component={Export} />
          <Route render={() => <Redirect to={`${path}/general`} />} />
        </Switch>
      </Tabs>
    </>
  )
}
Settings.defaultProps = {
  initialTab: 'generel',
  club: {
    countryCode: '',
  },
}

const enhancer = compose(
  withAuthenticationRequirement,
  connect(
    createStructuredSelector({
      companyName: getCompanyName,
      club: getActive,
    })
  )
)

export default enhancer(Settings)
