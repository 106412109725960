import React from 'react'
import IconBase from './IconBase'

const Logout = (props) => (
  <IconBase viewBox="0 0 56 45.7" {...props}>
    <g transform="translate(-381 -961)">
      <path d="m403.9 1003.3c-0.1-0.2-0.1-0.3-0.1-0.5 0-0.1-0.1-0.2-0.2-0.3-0.2-0.1-0.3-0.2-0.3-0.2s-0.1-0.1-0.4-0.1h-0.4-11.1c-1.5 0-3-0.6-4-1.7-1.1-1.1-1.7-2.5-1.7-4v-25.1c0-1.5 0.6-3 1.7-4 1.1-1.1 2.5-1.7 4-1.7h11.4c0.3 0 0.5-0.1 0.7-0.2 0.2-0.2 0.3-0.4 0.4-0.7s0.1-0.6 0.1-0.8v-0.9-0.7c0-0.6-0.5-1.1-1.1-1.1h-11.4c-2.7 0-5.4 1.1-7.3 3-2 1.9-3.1 4.5-3 7.3v25.1c0 2.7 1.1 5.4 3 7.3 1.9 2 4.5 3.1 7.3 3h11.4c0.3 0 0.5-0.1 0.7-0.2 0.2-0.2 0.3-0.4 0.4-0.7s0.1-0.6 0.1-0.8v-0.9c-0.2-0.8-0.2-1-0.2-1.1z" />
      <path d="m436.3 982.3l-19.4-19.4c-0.9-0.9-2.3-0.9-3.2 0-0.4 0.4-0.7 1-0.7 1.6v10.3h-16c-1.3 0-2.3 1-2.3 2.3v13.7c0 1.3 1 2.3 2.3 2.3h16v10.3c0 1.3 1 2.3 2.3 2.3 0.6 0 1.2-0.2 1.6-0.7l19.4-19.4c0.9-1 0.9-2.4 0-3.3z" />
    </g>
  </IconBase>
)

export default Logout
