import React, {useContext} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Redirect} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {getAuthenticated} from 'authentication'
import {getCompanyName, getTldLocale, getTld} from 'app/selectors'
import {Flex, Card, Button, Link, Image, Text, Box} from 'components'
import {Back} from 'components/icons'
import LoginForm from './LoginForm'
import {Context} from 'Cookies'
import getBadge from 'lib/getBadge'
import getLogo from 'lib/getLogo'

const Container = styled(Flex).attrs({
  flex: '1',
  alignItems: 'center',
  justifyContent: 'center',
})`
  min-height: 100vh;
`

const Login = ({
  location: {state},
  authenticated,
  companyName,
  tld,
  locale,
}) => {
  const {showModal} = useContext(Context)
  const [t] = useTranslation()

  return authenticated ? (
    <Redirect to="/overview" />
  ) : (
    <Container>
      <Helmet>
        <title>{t('Log ind | {{companyName}}', {companyName})}</title>
      </Helmet>

      <Box>
        <Box>
          <Image
            src={getLogo(locale, 'dark')}
            width={300}
            mx="auto"
            mb={4}
            display="block"
          />
        </Box>
        <Card secondaryLight width={450} alignItems="center" p={4}>
          <Box width={250}>
            {state && state.passwordLinkSent && (
              <Box my={3} bg="success" p={2} borderRadius={3}>
                <Text bold light center>
                  {t(
                    'Vi har sendt et link til den oplyste e-mailadresse, hvor du kan lave en ny kode.'
                  )}
                </Text>
              </Box>
            )}
            <LoginForm />
          </Box>
        </Card>
        <Flex width={450} my={3} justifyContent="center">
          <Box height={45} mr={3}>
            <Link
              external
              to="https://play.google.com/store/apps/details?id=dk.minforening.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <Image
                src={getBadge('googlePlay', locale)}
                style={{height: '100%'}}
              />
            </Link>
          </Box>
          <Box height={45}>
            <Link
              external
              to="https://itunes.apple.com/dk/app/minforening/id1149003433?l=da&mt=8"
            >
              <Image
                src={getBadge('appStore', locale)}
                style={{height: '100%'}}
              />
            </Link>
          </Box>
        </Flex>
        <Link secondary external to="https://minforening.dk">
          <Flex alignItems="center" justifyContent="center">
            <Box mr={2}>
              <Back fill="secondary" size={12} />
            </Box>
            {t('Tilbage til {{website}}', {website: `${companyName}.${tld}`})}
          </Flex>
        </Link>
        <Button block transparent center small mt={3} onClick={showModal}>
          <Text primary center>
            Cookies
          </Text>
        </Button>
      </Box>
    </Container>
  )
}

const enhancer = connect(
  createStructuredSelector({
    companyName: getCompanyName,
    authenticated: getAuthenticated,
    locale: getTldLocale,
    tld: getTld
  })
)

export default enhancer(Login)
