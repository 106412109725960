import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Trans, useTranslation} from 'react-i18next'
import {Text, Button, Flex, Box, Link} from 'components'
import {getPaymentAgreementAccepted, getActive} from 'clubs/selectors'
import countryCodes from 'lib/countryCodes'

const NoPayments = ({showCreateModal, paymentAgreementAccepted, club}) => {
  const [t] = useTranslation()
  const [isTrue, setIsTrue] = useState(false)

  // use memo or useEffect  to handle state change for 

  useEffect(() => {
    if (club?.countryCode === countryCodes.da) {
      setIsTrue(true)
    }
  }, [club?.countryCode, setIsTrue])

  return (
    <>
      <Box my={4}>
        <Text secondary center>
          {t('Her kan du udsende betalingsopkrævninger til dine medlemmer.')}
          {!paymentAgreementAccepted && (
            <>
              {isTrue ? (
                <Trans i18nKey="paymentAgreementAccepted">
                  <br />
                    {t('Ved at indsende ')}
                  <Link primary to="/settings/payments"> 
                    {t('MobilePay - foreningsaftale med MinForening')}
                  </Link>
                    {t(', så kan dine medlemmer nemt betale via MobilePay i appen.')}
                </Trans>
              ) : (
                <>
                  {t(', så dine medlemmer kan betale via MobilePay i appen.')}
                </>
              )}
            </>
          )}
        </Text>
      </Box>
      <Flex justifyContent="center">
        <Button small primary onClick={showCreateModal}>
          {t('Opret betaling')}
        </Button>
      </Flex>
    </>
  )
}

const enhancer = connect(
  createStructuredSelector({
    paymentAgreementAccepted: getPaymentAgreementAccepted,
    club: getActive,
  })
)

export default enhancer(NoPayments)
