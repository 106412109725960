import {omit} from 'lodash'
import {schema} from 'normalizr'
import {startOfToday, isBefore, parse} from 'date-fns'

import {
  ACTIVE,
  OVERDUE,
  CANCELLED,
  PREVIOUS,
  PAID_EXTERNALLY,
  PAID,
  NOT_PAID,
  REJECTED,
} from './constants'

export const payment = new schema.Entity(
  'payments',
  {},
  {
    processStrategy: (entity) => {
      const requestDate = parse(entity.requestDate)
      const paymentStartDate = entity.paymentStartDate
        ? parse(entity.paymentStartDate)
        : undefined

      const {approvable, pending, completed} = entity.payments.reduce(
        (result, payer) => {
          const mpCase =
            payer.status === NOT_PAID && payer.approved && !payer.paymentDate

          const key = mpCase
            ? 'completed'
            : (payer.status === PAID_EXTERNALLY || payer.status === REJECTED) &&
              !payer.isAccepted
            ? 'approvable'
            : payer.status === NOT_PAID
            ? 'pending'
            : 'completed'

          return {
            ...result,
            [key]: [
              ...result[key],
              {
                ...payer,
                status: mpCase ? PAID : payer.status,
                paymentDate: mpCase ? payer.approved : payer.paymentDate,
                amountAfterFees:
                  payer.status === CANCELLED
                    ? 0
                    : mpCase
                    ? payer.amount - Math.max(payer.amount * 0.01, 1)
                    : payer.amountAfterFees,
                amount:
                  (key === 'completed' && payer.status === REJECTED) ||
                  payer.status === CANCELLED
                    ? 0
                    : payer.amount,
                lastReminderSent:
                  payer.lastReminderSent && parse(payer.lastReminderSent),
              },
            ],
          }
        },
        {
          approvable: [],
          pending: [],
          completed: [],
        }
      )

      return {
        ...omit(entity, ['payments', 'totalUsers', 'paidUsers']),
        requestDate,
        paymentStartDate,
        totalPayers: entity.totalUsers,
        paidPayers: entity.paidUsers,
        payers: entity.payments,
        approvable,
        pending,
        completed,
        status:
          entity.totalUsers === completed.length &&
          entity.totalUsers !== 0 &&
          approvable.length === 0 &&
          pending.length === 0
            ? PREVIOUS
            : isBefore(requestDate, startOfToday())
            ? OVERDUE
            : ACTIVE,
      }
    },
  }
)

export const activityPayment = new schema.Entity(
  'activityPayments',
  {},
  {
    idAttribute: 'activityPaymentId',
    processStrategy: ({
      paymentDeadline,
      activityPaymentId: id,
      activityTitle: title,
      amount: individualAmount,
      ...entity
    }) => ({
      id,
      title,
      individualAmount,
      requestDate: parse(paymentDeadline),
      activity: true,
      ...entity,
    }),
  }
)

export default payment
