import {handleActions, combineActions} from 'redux-actions'
import {omit} from 'lodash'
import * as actions from './actions'

const initialState = {
  entities: {},
  totalViews: null,
  views: null,
}

const reducer = handleActions(
  {
    [actions.setViews]: (state, {payload: {views, totalViews}}) => ({
      ...state,
      views,
      totalViews,
    }),
    [combineActions(
      actions.fetch.succeeded,
      actions.create.succeeded,
      actions.update.succeeded
    )]: (
      state,
      {
        payload: {
          entities: {sponsors},
        },
      }
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        ...sponsors,
      },
    }),
    [actions.buyViews.succeeded]: (state, {payload: count}) => ({
      ...state,
      totalViews: state.totalViews + count,
    }),
    [actions.remove.succeeded]: ({entities, ...state}, {payload: id}) => ({
      ...state,
      entities: omit(entities, id),
    }),
  },
  initialState
)

export default reducer
