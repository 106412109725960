import React, {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Field} from 'formik'
import {
  isAfter,
  addHours,
  addMilliseconds,
  differenceInMilliseconds,
  isBefore,
  startOfYesterday,
  startOfToday,
  subDays,
  setMinutes,
  getHours,
  setHours,
} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {
  LabeledInput,
  LabeledDateInput,
  LabeledSelect,
  LabeledTextArea,
  LabeledSwitch,
  Text,
  Box,
  Flex,
  Button,
  Tooltip,
  PaymentMethods,
  PaymentMethodList,
  StyledCheckbox,
  Searchable,
  Input,
} from 'components'
import {Alert} from 'components/icons'
import {getPaymentAgreementAccepted, getActive, getActiveCurrency} from 'clubs/selectors'
import {typesById} from 'activities/constants'
import CoverImageInput from './CoverImage'
import ImagesInput from './Images'
import {asArray as recurringOptions} from 'activities/components/NewActivityForm/recurringOptions'
import ResponsibleSelect from './ResponsibleSelect'
import CoHostSelect from './CoHostSelect'
import VisibilitySelect from './VisiblilitySelect'
import styled from 'styled-components'
import {getClubPaymentMethods} from 'paymentMethods/actions'
import {toLower, includes} from 'lodash'
import countryCodes from 'lib/countryCodes'

const ListContainer = styled(Box)`
  max-height: 200px;
  overflow-y: scroll;

`

const Details = ({
  isEdit,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  paymentAgreementAccepted,
  currency,
  club,
  paymentMethodBulk,
  getClubPaymentMethods,
  //paymentMethods,
}) => {
  const [t] = useTranslation()
  const [kevinPaymentMethodIsActive, setKevinPaymentMethodIsActive] = useState(false)
  // const paymentMethods = [
  //   {
  //     id: 1,
  //     name: 'MobilPay',
  //   },
  //   {
  //     id: 2,
  //     name: 'Kevin',
  //   },
  //   {
  //     id: 3,
  //     name: 'ect1',
  //   },
  //   {
  //     id: 4,
  //     name: 'ect2',
  //   },
  //   {
  //     id: 5,
  //     name: 'ect3',
  //   },
  //   {
  //     id: 6,
  //     name: 'ect4',
  //   },
  //   {
  //     id: 7,
  //     name: 'ect5',
  //   },
  //   {
  //     id: 8,
  //     name: 'ect6',
  //   },
  //   {
  //     id: 9,
  //     name: 'ect7',
  //   },
  //   {
  //     id: 10,
  //     name: 'ect8',
  //   },
  // ];


  const togglePaymentMethod = useCallback(
    ({target: {value, checked}}) => {
      setFieldValue(
        'paymentMethods',
        checked
          ? [...paymentMethodBulk, value]
          : paymentMethodBulk.filter((paymentMethod) => paymentMethod.toString() !== value)
      )
    },
    [setFieldValue, paymentMethodBulk]
  )

  useEffect(() => {
    new Promise((resolve) => {
      getClubPaymentMethods({resolve})
    }).then(result => {
      let paymentMethods = result.filter(p => p.paymentMethodName.toLowerCase() == "kevin");
      if (paymentMethods.length > 0) {
        
        setKevinPaymentMethodIsActive(true);
      }
      
    })
  }, [getClubPaymentMethods, kevinPaymentMethodIsActive])


  return (
    <Flex flexDirection="column">
      <CoverImageInput
        name="coverImage"
        handleChange={(val) => setFieldValue('coverImage', val)}
        value={values.coverImage}
        type={typesById[values.type]}
      />
      <Flex flexDirection="column" p={3}>
        <Box mb={2}>
          <Field
            name="title"
            component={LabeledInput}
            label={`${t('Titel')} *`}
          />
        </Box>


        {(!isEdit || values.activityPayment) && !values.recurring && (
            <>
          <Box mb={2}>
            <Field
              readOnly={!paymentAgreementAccepted || isEdit}
              disabled={!paymentAgreementAccepted}
              name="amount"
              placeholder={
                !paymentAgreementAccepted
                  ? t('Kræver MobilePay - Foreningsaftale')
                  : undefined
              }
              component={LabeledInput}
              label={`${t('Beløb ({{currency}})', {currency})}`}
              onClick={() => setFieldTouched('amount', true, true)}
            />

            {touched.amount && isEdit && (
              <Text danger small>
                Det er ikke muligt at ændre beløb i en aktivitetsbetaling
              </Text>
              )}   
          </Box>    
        </>
        )}

        {/* <Box mb={2}>
          <ListContainer>

            <PaymentMethodList
                isEdit={isEdit}
                paymentMethods={paymentMethods}
                togglePaymentMethod={togglePaymentMethod}
                bulk={paymentMethodBulk}
            />

          </ListContainer>
        </Box> */}

        <Box mb={2}>
          <ResponsibleSelect />
        </Box>
        {values.amount && (
          <Box mb={2}>
            <CoHostSelect />
          </Box>
        )}
        <Box mb={2}>
          <Field name="location" component={LabeledInput} label={t('Sted')} />
        </Box>
        <Box mb={2}>
          <Field
            name="start"
            render={({field, form}) => (
              <LabeledDateInput
                {...field}
                viewDate={field.value || setMinutes(addHours(new Date(), 1), 0)}
                isValidDate={(current) => isAfter(current, startOfYesterday())}
                onChange={(val) => {
                  if (form.values.recurringUntill) {
                    form.setFieldValue(
                      'recurringUntill',
                      setHours(form.values.recurringUntill, getHours(val))
                    )
                  }

                  if (!form.values.end) {
                    form.setFieldValue('end', addHours(val, 1))
                  } else {
                    const diff = differenceInMilliseconds(
                      form.values.end,
                      form.values.start
                    )
                    form.setFieldValue('end', addMilliseconds(val, diff))
                  }

                  form.setFieldValue(field.name, val)
                }}
                onBlur={() => form.setFieldTouched(field.name, true)}
                error={form.touched[field.name] && form.errors[field.name]}
                label={`${t('Starter')} *`}
                renderAddon={(close) => (
                  <Button small block onClick={close}>
                    Ok
                  </Button>
                )}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Field
            name="end"
            render={({field, form}) => (
              <LabeledDateInput
                {...field}
                isValidDate={(current) =>
                  isAfter(
                    current,
                    values.start ? subDays(values.start, 1) : startOfYesterday()
                  )
                }
                onChange={(val) => form.setFieldValue(field.name, val)}
                onBlur={() => form.setFieldTouched(field.name, true)}
                error={form.touched[field.name] && form.errors[field.name]}
                label={t('Slutter')}
                renderAddon={(close) => (
                  <Button small block onClick={close}>
                    Ok
                  </Button>
                )}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Field
            name="deadline"
            render={({field, form}) => (
              <LabeledDateInput
                {...field}
                viewDate={field.value || setHours(startOfToday(), 12)}
                isValidDate={(current) => isBefore(current, values.start)}
                onChange={(val) => form.setFieldValue(field.name, val)}
                onBlur={() => form.setFieldTouched(field.name, true)}
                error={form.touched[field.name] && form.errors[field.name]}
                label={`${t('Svarfrist')} ${values.amount ? ' *' : ''}`}
                renderAddon={(close) => (
                  <Button small block onClick={close}>
                    {t('Ok')}
                  </Button>
                )}
              />
            )}
          />
        </Box>
        {values.deadline && (
          <Box mb={2}>
            <LabeledSwitch
              name="twoDaysReminder"
              label={t('Påmindelse to dage før')}
              handleChange={({target: {checked}}) =>
                setFieldValue('twoDaysReminder', checked)
              }
              value={values.twoDaysReminder}
            />
          </Box>
        )}
        <Box mb={2}>
          <Field
            name="description"
            render={({field, form}) => (
              <LabeledTextArea
                {...field}
                error={form.touched.description && form.errors.description}
                label={t('Beskrivelse')}
              />
            )}
          />
        </Box>
        <ImagesInput
          setFieldValue={setFieldValue}
          value={values.images}
          id={values.id}
          isRecurring={!!values.recurring}
        />
        {!isEdit && !values.amount && (
          <Box mb={2}>
            <LabeledSelect
              required
              name="recurring"
              options={recurringOptions}
              label={t('Gentaget aktivitet')}
              value={values.recurring}
              title={t(recurringOptions[values.recurring].name)}
              renderItem={({id, name}, hide) => (
                <Box
                  p={2}
                  onClick={() => {
                    setFieldValue('recurring', id)
                    hide()
                  }}
                >
                  <Text secondary>{t(name)}</Text>
                </Box>
              )}
            />
          </Box>
        )}
        {!isEdit && values.recurring > 0 && (
          <Box mb={2}>
            <Field
              name="recurringUntill"
              render={({field, form}) => (
                <LabeledDateInput
                  {...field}
                  timeFormat={false}
                  isValidDate={(current) => isAfter(current, values.start)}
                  onChange={(val) =>
                    form.setFieldValue(
                      field.name,
                      setHours(
                        val,
                        form.values.start ? getHours(form.values.start) : 12
                      )
                    )
                  }
                  onBlur={() => form.setFieldTouched(field.name, true)}
                  error={form.touched[field.name] && form.errors[field.name]}
                  label={t('Gentag indtil')}
                  renderAddon={(close) => (
                    <Button small block onClick={close}>
                      {t('Ok')}
                    </Button>
                  )}
                />
              )}
            />
          </Box>
        )}
        {!values.amount && !values.limit && (
          <Box mb={2}>
            <LabeledSwitch
              disabled={isEdit}
              name="forcedParticipation"
              label={t('Inviterede deltager automatisk')}
              handleChange={({target: {checked}}) =>
                setFieldValue('forcedParticipation', checked)
              }
              value={values.forcedParticipation}
            />
          </Box>
        )}
        {!values.forcedParticipation && (
          <Box mb={2}>
            <Field
              name="limit"
              component={LabeledInput}
              label={t('Maks antal deltagere')}
            />
          </Box>
        )}  
        <Box mb={2}>
          <LabeledSwitch
            name="sendNotification"
            label={t('Invitationsnotifikation')}
            handleChange={({target: {checked}}) =>
              setFieldValue('sendNotification', checked)
            }
            value={values.sendNotification}
          />
        </Box>
        <Box mb={2}>
          <VisibilitySelect />
        </Box>
        <Box mb={2}>
          <LabeledSwitch
            name="participantsVisible"
            label={t('Vis deltagerliste')}
            handleChange={({target: {checked}}) =>
              setFieldValue('participantsVisible', checked)
            }
            value={values.participantsVisible}
          />
        </Box>
        <Box mb={2}>
          <LabeledSwitch
            name="commentsEnabled"
            label={t('Kommentarspor aktiveret')}
            handleChange={({target: {checked}}) =>
              setFieldValue('commentsEnabled', checked)
            }
            value={values.commentsEnabled}
          />
        </Box>
        {/* {!isEdit && 
          <Box mb={2} mb={2}>
            <Flex alignItems="center">
              <Field name="kevinPaymentDisabled"> 
                {({
                  field: {value, name},
                  form: {setFieldValue, setFieldTouched},
                }) => (
                  <StyledCheckbox
                    checked={!value}
                    disabled={!kevinPaymentMethodIsActive}
                    onChange={({target: {checked}}) => {
                      setFieldTouched('methods')
                      setFieldValue(name, !checked)
                    }}
                  />
                )}
              </Field>
              <Box mx={2}>
                <Text>{t('MinForening betalingsløsning')}</Text>
              </Box>
              <Tooltip text={t('kevinPaymentExplanation')}>
                {({show, hide}) => (
                  <Alert
                    onMouseEnter={show}
                    onMouseLeave={hide}
                    fill="secondary"
                    size={16}
                  />
                )}
              </Tooltip>
            </Flex>
          </Box>
        } */}
      </Flex>
    </Flex>
  )
}

const enhancer = connect(
  createStructuredSelector({
    club: getActive,
    currency: getActiveCurrency,
    paymentAgreementAccepted: getPaymentAgreementAccepted,
  }), {
    getClubPaymentMethods: getClubPaymentMethods.requested
  }
)

export default enhancer(Details)
