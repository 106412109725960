import {createSelector} from 'reselect'
import {get, values, sortBy} from 'lodash'
import {compareDesc, isBefore, startOfToday} from 'date-fns'
import {ACTIVE, OVERDUE, PREVIOUS} from './constants'
import {getActivities} from 'activities/selectors'

export const getIsFetching = (state) => state.payments.isFetching
export const getIsFetched = (state) => state.payments.isFetched
export const getAll = (state) => values(state.payments.entities)
export const getOne = (state, id) => {
  const payment = get(state, `payments.entities[${id}]`)

  if (!payment) {
    return payment
  }

  if (payment.activity) {
    return {
      ...payment,
      completed: get(payment, 'completed', []),
      approvable: get(payment, 'approvable', []),
      pending: get(payment, 'pending', []),
    }
  }

  return get(payment, 'status') === PREVIOUS
    ? {
        ...payment,
        completed: sortBy(payment.completed, ({approved}) => approved).sort(
          (a, b) => compareDesc(a.approved, b.approved)
        ),
      }
    : payment
}
export const getAllRegular = createSelector([getAll], (payments) =>
  payments.filter(({activity}) => !activity)
)
export const getAllActivity = createSelector([getAll], (payments) =>
  payments.filter(({activity}) => activity)
)
export const getGrouped = createSelector(
  [getAll, getActivities],
  (payments, activities) =>
    payments.reduce(
      (acc, payment) => {
        const key = !payment.activity
          ? payment.status
          : isBefore(
              get(activities, `[${payment.id}].end`, payment.requestDate),
              startOfToday()
            )
          ? PREVIOUS
          : 'ACTIVITY'

        return {
          ...acc,
          [key]: [...acc[key], payment],
        }
      },
      {
        [ACTIVE]: [],
        [OVERDUE]: [],
        [PREVIOUS]: [],
        ACTIVITY: [],
      }
    )
)
export const getNumberOfActionsRequired = createSelector(
  [getAllRegular],
  (payments) =>
    payments.reduce((total, current) => total + current.approvable.length, 0)
)
export const getNumberOfOutstanding = createSelector(
  [getAllRegular],
  (payments) =>
    payments.reduce(
      (total, {pending}) =>
        total +
        pending.filter(({requestDate}) => isBefore(requestDate, startOfToday()))
          .length,
      0
    )
)

export const getLatestInfo = createSelector([getAllRegular], (payments) =>
  get(payments[payments.length - 1], 'paymentDescription')
)
