import React from 'react'
import {withRouter} from 'react-router-dom'
import {Flex, Box} from 'components'
import Tab from './Tab'

const Sidetabs = ({tabs, match: {url}, location: {pathname}, children}) => (
  <Box>
  
      {tabs.map(tab => (
        <Tab
          {...tab}
          key={tab.id}
          active={pathname.indexOf(tab.id) > -1}
          path={`${url}/${tab.id}`}
        />
      ))}
   

    <Box>
      {children}
    </Box>
  </Box>
)

Sidetabs.defaultProps = {
  tabs: [],
}

export default withRouter(Sidetabs)
