import React, {useCallback} from 'react'
import {Formik, Form, Field} from 'formik'
import {Box, Flex} from '@rebass/grid'
import {FormikInput as Input, Button} from 'components'
import {useTranslation} from 'react-i18next'

const EditForm = ({ ...props}) => {
  const [t] = useTranslation()

  return (
    <Formik 
      {...props}
    >
      {() => (
        <Form>
          <Field name="id" component="input" type="hidden" />
          <Flex>
            <Box mr={3} flex="2">
              <Field name="title" component={Input} />
            </Box>
            <Box mr={3} flex="1">
              <Field name="maxUsers" component={Input} placeholder={"Max Users"} /> 
            </Box>
            <Button type="submit" success>
              {t('Ok')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
export default EditForm
