import React from 'react'
import {Formik, Form, Field} from 'formik'
import Yup from 'lib/yup'
import i18n from '../../../i18n'
import {FormikInput as Input, Text, Box, Flex, Button} from 'components'
import {useTranslation} from 'react-i18next'

const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().required().email(),
  zip: Yup.string()
    .required()
    .max(4)
    .min(4)
    .test('numbers', i18n.t('validation:mustBeANumber'), (value) =>
      /^[0-9]+/.test(value)
    ),
  password: Yup.string().required(),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null]),
})

const initialValues = {
  firstName: '',
  surname: '',
  email: '',
  zip: '',
  password: '',
  passwordConfirmation: '',
}

const RequiredParent = ({error, ...props}) => {
  const [t] = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...props}
    >
      {({isValid}) => (
        <Form>
          <Flex>
            <Box flex="1" mr={3}>
              <Field
                small
                name="firstName"
                placeholder={`${t('Fornavn')} *`}
                component={Input}
              />
            </Box>
            <Box flex="1">
              <Field
                small
                name="surname"
                placeholder={`${t('Efternavn')} *`}
                component={Input}
              />
            </Box>
          </Flex>
          <Field
            small
            name="email"
            placeholder={`${t('Email')} *`}
            type="email"
            component={Input}
          />
          <Field
            small
            name="zip"
            placeholder={`${t('Postnummer')} *`}
            component={Input}
          />
          <Field
            small
            name="password"
            placeholder={`${t('Kode')} *`}
            type="password"
            component={Input}
          />
          <Field
            small
            name="passwordConfirmation"
            placeholder={`${t('Kode gentaget')} *`}
            type="password"
            component={Input}
          />
          {error && (
            <Text danger mb={3}>
              {error}
            </Text>
          )}
          <Button small block type="submit" disabled={!isValid}>
            {t('Godkend')}!
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default RequiredParent
