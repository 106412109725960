import React from 'react'
import IconBase from './IconBase'

const Pin = props => (
  <IconBase viewBox="0 0 8.7 11.81" {...props}>
    <g transform="translate(267.72 229.4)">
      <path d="m-263.37-229.4c-2.4 0-4.35 1.95-4.35 4.35 0 0.61 0.13 1.22 0.38 1.77 1.03 2.02 2.3 3.9 3.79 5.6 0.09 0.1 0.24 0.11 0.34 0.03l0.03-0.03c1.49-1.7 2.76-3.58 3.79-5.6 0.25-0.56 0.38-1.16 0.38-1.77-0.01-2.4-1.96-4.35-4.36-4.35zm0 6.61c-1.25 0-2.26-1.01-2.26-2.26s1.01-2.26 2.26-2.26 2.26 1.01 2.26 2.26-1.01 2.26-2.26 2.26z" />
    </g>
  </IconBase>
)

export default Pin
