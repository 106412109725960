import {takeEvery, select, put, call, all} from 'redux-saga/effects'
import {api} from 'app/sagas'
import {getActive} from 'clubs'
import imageToBase64 from 'lib/imageToBase64'
import * as actions from './actions'

function* watchFetch() {
  yield takeEvery(actions.fetch.requested, fetch)
}

function* fetch() {
  const {id} = yield select(getActive)

  try {
    const {
      sponsors,
      sponsorViewsShown: views,
      sponsorViewsTotal: totalViews,
    } = yield call(api, `/sponsors?clubId=${id}`, {
      version: null,
    })

    yield all([
      put(actions.fetch.succeeded(sponsors)),
      put(actions.setViews({views, totalViews})),
    ])
  } catch (error) {
    yield put(actions.fetch.failed(error.message))
  }
}

function* watchCreate() {
  yield takeEvery(actions.create.requested, create)
}

function* create({payload: values, meta: {resolve, reject}}) {
  const {id} = yield select(getActive)
  const logoBase64 = yield call(imageToBase64, values.logoBase64)

  try {
    const {sponsors} = yield call(api, `/sponsors/clubs/${id}`, {
      method: 'post',
      version: null,
      body: {
        ...values,
        logoBase64,
      },
    })

    yield put(actions.create.succeeded(sponsors))
    yield call(resolve)
  } catch (error) {
    yield put(actions.create.failed(error.message))
    yield call(reject, error.message)
  }
}

function* watchUpdate() {
  yield takeEvery(actions.update.requested, update)
}

function* update({
  payload: {id: sponsorId, ...values},
  meta: {resolve, reject},
}) {
  const {id} = yield select(getActive)
  const newImage = !!values.logoBase64
  const logoBase64 = yield call(imageToBase64, values.logoBase64)

  try {
    const response = yield call(
      api,
      `/sponsors/clubs/${id}?sponsorId=${sponsorId}`,
      {
        method: 'PUT',
        version: null,
        body: {
          ...values,
          logoBase64,
          newImage,
        },
      }
    )

    yield put(actions.update.succeeded(response))
    yield call(resolve)
  } catch (error) {
    yield put(actions.update.failed(error))
    yield call(reject, error.message)
  }
}

function* watchRemove() {
  yield takeEvery(actions.remove.requested, remove)
}

function* remove({payload: id}) {
  const {id: clubId} = yield select(getActive)

  try {
    yield call(api, `/sponsors/clubs/${clubId}?sponsorId=${id}`, {
      version: null,
      method: 'delete',
    })

    yield put(actions.remove.succeeded(id))
  } catch (error) {
    yield put(actions.remove.failed(error.message))
  }
}

function* watchBuyViews() {
  yield takeEvery(actions.buyViews.requested, buyViews)
}

function* buyViews({payload: count, meta: {resolve, reject}}) {
  const {id} = yield select(getActive)

  try {
    const response = yield call(
      api,
      `/sponsors/clubs/${id}/order?count=${count}`,
      {version: null, method: 'post'}
    )

    yield put(actions.buyViews.succeeded(response))
    yield call(resolve)
  } catch (error) {
    yield call(reject, error.message)
  }
}

export default [
  watchFetch,
  watchCreate,
  watchUpdate,
  watchRemove,
  watchBuyViews,
]
