import React from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {Flex, Box} from '@rebass/grid'
import {isMobile} from 'react-device-detect'
import {Image, Text, H2, Link} from 'components'
import {get as getClub} from 'signup/club'
import {useTranslation} from 'react-i18next'
import getBadge from 'lib/getBadge'

const Done = ({club}) => {
  const [t, i18n] = useTranslation()

  return (
    <>
      <Box mb={2} mx="auto">
        <Text>{t('Du er nu medlem af')}</Text>
      </Box>

      <Flex mx="auto" mb={4}>
        <Box mr={2}>
          <Image round width="30" height="30" src={club.imageUrl} />
        </Box>
        <H2>
          <strong>{club.clubName}</strong>
        </H2>
      </Flex>

      <Box mx="auto" width={250}>
        <Text center>{t('youCanNowGetTheApp')}</Text>
      </Box>

      <Flex justifyContent="center" width={300} my={3} mx="auto">
        <Box height={40} mr={2}>
          <Link
            external
            to="https://play.google.com/store/apps/details?id=dk.minforening.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <Image
              src={getBadge('googlePlay', i18n.language)}
              style={{height: '100%'}}
            />
          </Link>
        </Box>
        <Box height={40}>
          <Link
            external
            to="https://itunes.apple.com/dk/app/minforening/id1149003433?l=da&mt=8"
          >
            <Image
              src={getBadge('appStore', i18n.language)}
              style={{height: '100%'}}
            />
          </Link>
        </Box>
      </Flex>

      {isMobile ? (
        <>
          <Box mx="auto" mb={3}>
            <Text>
              <small>
                <strong>{t('Download vores applikation her')}</strong>
              </small>
            </Text>
          </Box>
        </>
      ) : (
        <>
          <Box mx="auto" width={250}>
            <Text center>{t('downloadTheAppOnYouPhoneOrTablet')}</Text>
          </Box>
        </>
      )}
    </>
  )
}
const enhancer = connect(
  createStructuredSelector({
    club: getClub,
  })
)

export default enhancer(Done)
