import React from 'react'
import {Flex, Box} from '@rebass/grid'
import {Trans, useTranslation} from 'react-i18next'
import {DownloadTheApp, Text} from 'components'

const Invalid = () => {
  const [t] = useTranslation()

  return (
    <Flex flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Text bg="danger" light center>
          <strong>{t('invitationLinkVoid')}</strong>
        </Text>
      </Box>

      <Box style={{maxWidth: 350}}>
        <Box mb={4}>
          <Text center>
            <Trans i18nKey="contactVoidInvitationSender">
              Tag kontakt til personen du modtog linket af, og se om du kan få
              et <strong>gyldigt invitationslink</strong> til din forening.
            </Trans>
          </Text>
        </Box>
        <Box mb={3}>
          <Text center>
            <Trans i18nKey="theAppCanBeDownloaded">
              MinForening App kan downloades via <strong>Google Play</strong>{' '}
              eller <strong>App Store</strong>
            </Trans>
          </Text>
        </Box>

        <DownloadTheApp />
      </Box>
    </Flex>
  )
}

export default Invalid
