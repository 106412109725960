import React from 'react'
import {Foldable, Text, Box, Flex} from 'components'
import Body from './Body'
import Header from './Header'
import {useTranslation} from 'react-i18next'

const MemberTable = ({
  members,
  fetchRelations,
  bulk,
  toggleBulk,
  toggleAll,
  openMember,
  showJoinModal,
}) => {
  const [t] = useTranslation()

  return (
    <Foldable
      canClose={false}
      renderHeader={() => (
        <Flex alignItems="center">
          <Box mr={3}>
            <input
              type="checkbox"
              onChange={toggleAll}
              onClick={(e) => e.stopPropagation()}
            />
          </Box>
          <Box>
            <Text light bold>
              {t('Medlemmer')} ( {members.length} )
            </Text>
          </Box>
        </Flex>
      )}
    >
      <Header />
      <Body
        members={members}
        bulk={bulk}
        toggleBulk={toggleBulk}
        fetchRelations={fetchRelations}
        openMember={openMember}
        showJoinModal={showJoinModal}
      />
    </Foldable>
  )
}
export default MemberTable
