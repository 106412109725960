import React from 'react'
import {get} from 'lodash'
import {useTranslation} from 'react-i18next'
import {Row, Cell} from 'components/Table'
import {Box, Image, Button, Text} from 'components'
import {withToggle} from 'lib/hoc'
import memberDefault from 'images/member-default.png'
import price from 'lib/price'
import InternalNoteModal from './InternalNoteModal'
import {PAID} from 'payments/constants'

const PayersTableRow = ({
  id,
  approved,
  paymentId,
  paymentDate,
  note,
  internalNote,
  internalNoteModalVisible,
  showInternalNoteModal,
  hideInternalNoteModal,
  status,
  paymentStatus,
  renderStatus,
  requestDate,
  clubPaymentMethodName,
  lastReminderSent,
  user: {userId: memberId, firstName, surname, headerImage, isInactiveUser},
  activityPayment,
  filteredIsAccepted,
  ...props
}) => {
  const [t] = useTranslation()
  const amount = get(activityPayment, 'amount', props.amount)
  const amountAfterFees = get(
    activityPayment,
    'amountAfterTax',
    props.amountAfterFees
  )

  return (
    filteredIsAccepted ? ( 
    <Row aproved>
      <Cell width={45} justifyContent="center" alignItems="center">
        <Image round src={headerImage || memberDefault} height="25" />
      </Cell>
      <Cell width={1 / 7} alignItems="center" protectOverflow>
        {firstName}
      </Cell>
      <Cell width={1 / 7} alignItems="center">
        {surname}
      </Cell>
      <Cell width={1 / 12} alignItems="center">
        {isInactiveUser ? t(' (Får mails)') : t('') }
      </Cell>
      <Cell width={1 / 8} alignItems="center">
        {status === PAID || paymentStatus === PAID ? (
          <>
            {price(amount)}
            <Box ml={2}>
              <Text
                small
                secondary
                title={`${t(
                  'MobilePay tager 1% af transaktionsbeløbet, dog min 1,00 kr.'
                )}`}
              >
                ({price(amountAfterFees)})
              </Text>
            </Box>
          </>
        ) : (
          price(amount)
        )}
      </Cell>

      <Cell width={1 / 12} alignItems="center">
        <Button pirmary tiny onClick={showInternalNoteModal}>
          {internalNote ? t('Se note') : t('Tilføj note')}
        </Button>

        {internalNoteModalVisible && (
          <InternalNoteModal
            hide={hideInternalNoteModal}
            initialValues={{id, internalNote, memberId, paymentId}}
          />
        )}
      </Cell>
      <Cell flex="1" inactive>
        {renderStatus({
          status: activityPayment ? paymentStatus : status,
          approved,
          note,
          lastReminderSent,
          requestDate,
          paymentDate,
          memberId,
          isInactiveUser,
          clubPaymentMethodName,
          id,
        })}
      </Cell>
    </Row>
    ) : (   
    <Row>
      <Cell width={45} justifyContent="center" alignItems="center">
        <Image round src={headerImage || memberDefault} height="25" />
      </Cell>
      <Cell width={1 / 7} alignItems="center" protectOverflow>
        {firstName}
      </Cell>
      <Cell width={1 / 7} alignItems="center">
        {surname}
      </Cell>
      <Cell width={1 / 12} alignItems="center">
        {isInactiveUser ? t(' (Får mails)') : t('') }
      </Cell>
      <Cell width={1 / 8} alignItems="center">
        {status === PAID || paymentStatus === PAID ? (
          <>
            {price(amount)}
            <Box ml={2}>
              <Text
                small
                secondary
                title={`${t(
                  'MobilePay tager 1% af transaktionsbeløbet, dog min 1,00 kr.'
                )}`}
              >
                ({price(amountAfterFees)})
              </Text>
            </Box>
          </>
        ) : (
          price(amount)
        )}
      </Cell>

      <Cell width={1 / 12} alignItems="center">
        <Button pirmary tiny onClick={showInternalNoteModal}>
          {internalNote ? t('Se note') : t('Tilføj note')}
        </Button>

        {internalNoteModalVisible && (
          <InternalNoteModal
            hide={hideInternalNoteModal}
            initialValues={{id, internalNote, memberId, paymentId}}
          />
        )}
      </Cell>
      <Cell flex="1">
        {renderStatus({
          status: activityPayment ? paymentStatus : status,
          approved,
          note,
          lastReminderSent,
          requestDate,
          paymentDate,
          memberId,
          clubPaymentMethodName,
          isInactiveUser,
          id,
        })}
      </Cell>
    </Row>
    )
  )
}

const enhancer = withToggle('internalNoteModal')

export default enhancer(PayersTableRow)
