import {createActions} from 'redux-actions'
import {normalize} from 'normalizr'
import schema from './schema'

export const {fetch, setViews, create, update, remove, buyViews} =
  createActions(
    {
      FETCH: {
        REQUESTED: undefined,
        FAILED: undefined,
        SUCCEEDED: (response) => normalize(response, [schema]),
      },
      SET_VIEWS: undefined,
      CREATE: {
        REQUESTED: [
          ({resolve, reject, ...values}) => values,
          ({resolve, reject}) => ({
            resolve,
            reject,
          }),
        ],
        FAILED: undefined,
        SUCCEEDED: (response) => normalize(response, [schema]),
      },
      UPDATE: {
        REQUESTED: [
          ({resolve, reject, ...values}) => values,
          ({resolve, reject}) => ({
            resolve,
            reject,
          }),
        ],
        FAILED: undefined,
        SUCCEEDED: (response) => normalize(response, schema),
      },
      REMOVE: {
        REQUESTED: undefined,
        FAILED: undefined,
        SUCCEEDED: undefined,
      },
      BUY_VIEWS: {
        REQUESTED: [
          ({count}) => count,
          ({resolve, reject}) => ({
            resolve,
            reject,
          }),
        ],
        FAILED: undefined,
        SUCCEEDED: undefined,
      },
    },
    {prefix: 'sponsors'}
  )
