import React from 'react'
import IconBase from './IconBase'

const LogoWithTagline = props => (
  <IconBase viewBox="0 0 2477.3 470.6" {...props}>
    <path
      d="M1247.4,141.2c40.6,0,73.6,32.8,73.6,73.2c0,40.4-33,73.2-73.6,73.2c-40.6,0-73.6-32.8-73.6-73.2
	C1173.9,174.1,1206.9,141.2,1247.4,141.2 M1247.4,108.1c-59.1,0-106.9,47.6-106.9,106.4c0,58.8,47.9,106.4,106.9,106.4
	c59.1,0,106.9-47.6,106.9-106.4C1354.4,155.7,1306.5,108.1,1247.4,108.1L1247.4,108.1z"
    />
    <path
      d="M1701,231c0.9-5.4,1.3-10.9,1.3-16.6c0-5.6-0.5-11.2-1.3-16.6c-8-50.9-52.2-89.8-105.6-89.8c-53.4,0-97.6,38.9-105.6,89.8
	c-0.9,5.4-1.3,10.9-1.3,16.6c0,5.6,0.5,11.2,1.3,16.6c0.1,0.8,0.3,1.6,0.4,2.4c2.1,11.9,6.3,23,12,33.2
	c18.3,32.4,53.2,54.2,93.2,54.2c40,0,74.9-21.9,93.2-54.2H1647c-13.3,13-31.5,21-51.6,21s-38.3-8-51.6-21c-9.2-9-16-20.4-19.5-33.2
	c-0.2-0.8-0.4-1.6-0.6-2.4h143.3H1701z M1523.8,197.9c7.6-32.4,36.8-56.6,71.6-56.6s64.1,24.2,71.6,56.6H1523.8z"
    />
    <path
      d="M890.7,108.1h-6c-57.2,0-103.9,46.5-103.9,103.4v109.4h33.4V211.5c0-18.6,7.4-36.2,20.8-49.6c13.4-13.3,31.1-20.7,49.8-20.7
	h6c18.7,0,36.4,7.3,49.8,20.7c13.4,13.3,20.8,30.9,20.8,49.6v109.4h33.4V211.5C994.7,154.6,947.9,108.1,890.7,108.1z"
    />
    <path
      d="M1833.5,108.1h-6c-57.2,0-103.9,46.5-103.9,103.4v109.4h33.4V211.5c0-18.6,7.4-36.2,20.8-49.6
	c13.4-13.3,31.1-20.7,49.8-20.7h6c18.7,0,36.4,7.3,49.8,20.7c13.4,13.3,20.8,30.9,20.8,49.6v109.4h33.4V211.5
	C1937.4,154.6,1890.6,108.1,1833.5,108.1z"
    />
    <path
      d="M2137.5,108.1h-6c-57.2,0-103.9,46.5-103.9,103.4v109.4h33.3V211.5c0-18.6,7.4-36.2,20.8-49.6
	c13.4-13.3,31.1-20.7,49.8-20.7h6c18.7,0,36.4,7.3,49.8,20.7c13.4,13.3,20.8,30.9,20.8,49.6v109.4h33.4V211.5
	C2241.4,154.6,2194.7,108.1,2137.5,108.1z"
    />
    <polygon points="527,246.7 404.6,23.9 359.1,320.8 392.9,320.8 422,131 527,318.3 632,131 661.1,320.8 694.8,320.8 649.3,23.9 " />
    <g>
      <rect x="717.2" y="105.2" width="33.4" height="215.7" />
      <ellipse cx="733.9" cy="48.8" rx="25" ry="24.9" />
    </g>
    <g>
      <rect x="1965.3" y="105.2" width="33.4" height="215.7" />
      <ellipse cx="1990.6" cy="48.8" rx="25" ry="24.9" />
    </g>
    <path
      d="M1482,108.1h-3c-57.2,0-103.9,46.5-103.9,103.4v109.4h33.4V211.5c0-18.6,7.4-36.2,20.8-49.6c13.4-13.3,31.1-20.7,49.8-20.7
	h3V108.1z"
    />
    <path
      d="M2422.8,307.2c32.5-18.2,54.5-52.9,54.5-92.7c0-58.8-47.9-106.4-106.9-106.4c-59.1,0-106.9,47.6-106.9,106.4
	c0,58.8,47.9,106.4,106.9,106.4c0,0,0.1,0,0.1,0c25.6,0.1,46.4,20.8,46.4,46.2c0,25.5-20.9,46.2-46.5,46.2v33.2
	c44.1,0,79.8-35.6,79.8-79.4C2450.2,343.2,2439.6,321.7,2422.8,307.2z M2297.6,214.4c0-40.4,33-73.2,73.6-73.2
	c40.6,0,73.6,32.8,73.6,73.2c0,40.3-32.9,73.1-73.5,73.2c0,0-0.1,0-0.1,0v0h0C2330.6,287.6,2297.6,254.8,2297.6,214.4z"
    />
    <g>
      <path
        fill="#F2F19E"
        d="M321.6,85.8C274,3.8,168.6-24.3,86.2,23C3.8,70.3-24.5,175.2,23.1,257.2c47.6,82,153,110.1,235.4,62.8
		S369.2,167.8,321.6,85.8z M172.4,267.9c-53.5,0-97-43.2-97-96.5c0-53.3,43.4-96.5,97-96.5s97,43.2,97,96.5
		C269.3,224.7,225.9,267.9,172.4,267.9z"
      />
      <path
        fill="#ABD495"
        d="M123.9,87.9L86.2,23C31,54.7,0.1,112.3,0,171.5l75.4,0C75.4,135.8,94.9,104.6,123.9,87.9z"
      />
      <path
        fill="#84BFD8"
        d="M75.4,171.5L75.4,171.5l-75.4,0c0,29.1,7.4,58.7,23.1,85.7c15.7,27,37.7,48.2,63.1,62.8l37.7-65
		C94.9,238.3,75.4,207.2,75.4,171.5z"
      />
      <path
        fill="#9A8EC5"
        d="M172.4,267.9c-17.7,0-34.2-4.7-48.5-12.9l-37.7,65c51.6,29.5,117.1,31.7,172.3,0L220.8,255
		C206.6,263.2,190,267.9,172.4,267.9z"
      />
      <path
        fill="#D59093"
        d="M269.3,171.5c0,35.7-19.5,66.9-48.5,83.5l37.7,64.9c55.2-31.7,86.1-89.3,86.2-148.5L269.3,171.5z"
      />
      <path
        fill="#DCB288"
        d="M269.3,171.5L269.3,171.5l75.4,0c0-29.1-7.4-58.7-23.1-85.7c-15.7-27-37.7-48.2-63.1-62.8l-37.7,65
		C249.8,104.6,269.3,135.8,269.3,171.5z"
      />
    </g>
    <path d="M1022.4,322.4h33.4V197h66.7v-33.5h-66.7V58.6h146.6V25.4h-179.9C1022.4,25.4,1022.9,322.8,1022.4,322.4z" />
    <rect x="981" y="374.6" fill="none" width="1417.2" height="89.5" />
    <text
      transform="matrix(1 0 0 1 980.9736 445.6435)"
      font-family="MyriadPro-Regular"
      font-size="100px"
    >
      Gratis app til dig og din forening
    </text>
  </IconBase>
)

export default LogoWithTagline
