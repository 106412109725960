import {createSelector} from 'reselect'

export const getCompanyName = () =>
  ({
    'web.minforening.dk': 'MinForening',
    'web.mehrverein.de': 'MehrVerein',
    'web.wiandi.co.uk': 'Wiandi',
    'web.wiandi.es': 'Wiandi',
    'web.wiandi.pl': 'Wiandi',
    'web.wiandi.cz': 'Wiandi',
    'web.min-forening.no': 'MinForening',
    'web.wiandi.sk': 'Wiandi',
  }[window.location.hostname] || 'MinForening')

export const getTopLevelDomainName = () =>
  ({
    'web.minforening.dk': 'minforening.dk',
    'web.mehrverein.de': 'mehrverein.de',
    'web.wiandi.co.uk': 'wiandi.co.uk',
    'web.wiandi.es': 'wiandi.es',
    'web.wiandi.pl': 'wiandi.pl',
    'web.wiandi.cz': 'wiandi.cz',
    'web.min-forening.no': 'min-Forening.no',
    'web.wiandi.sk': 'wiandi.sk',
  }[window.location.hostname] || 'MinForening')

export const getTld = createSelector(
  [getTopLevelDomainName],
  (companyName) =>
    ({
      'minforening.dk': 'dk',
      'mehrverein.de': 'de',
      'wiandi.co.uk': 'co.uk',
      'wiandi.es': 'es',
      'wiandi.pl': 'pl',
      'wiandi.cz': 'cz',
      'min-Forening.no': 'no',
      'wiandi.sk': 'sk',
    }[companyName])
)

export const getTldLocale = createSelector(
  [getTopLevelDomainName],
  (companyName) =>
    ({
      'minforening.dk': 'da',
      'mehrverein.de': 'de',
      'wiandi.co.uk': 'en',
      'wiandi.es': 'es',
      'wiandi.pl': 'pl',
      'wiandi.cz': 'cz',
      'min-Forening.no': 'no',
      'wiandi.sk': 'sk',
    }[companyName] || 'da')
)

export const getTldEmail = createSelector(
  [getTopLevelDomainName],
  (companyName) =>
    ({
      'minforening.dk': 'ambassadør@minforening.dk',
      'mehrverein.de': 'botschafter@mehrverein.de',
      'wiandi.co.uk': 'ambassador@wiandi.co.uk',
      'wiandi.es': 'ambassadør@minforening.dk',
      'wiandi.pl': 'ambassadør@minforening.dk',
      'wiandi.cz': 'ambassadør@minforening.dk',
      'min-Forening.no': 'ambassadør@minforening.dk',
      'wiandi.sk': 'ambassadør@minforening.dk',
    }[companyName] || 'ambassadør@minforening.dk')
)
